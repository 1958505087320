import { useEffect, useState } from "react";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { UserRole } from "../../../utils/types";
interface PassDetailsProps {
  operationalNeed: string;
  setOperationalNeed: (newOperationalNeed: string) => void;
  airportId: string;
  setAirportId: (newAirportId: string) => void;
  rangeType: string;
  setRangeType: (newRangeType: string) => void;
  startTime: string;
  setStartTime: (newStartTime: string) => void;
  endTime: string;
  setEndTime: (newEndTime: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}
export const PassDetails = (props: PassDetailsProps) => {
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [allRangeTypesAllowed, setAllRangeTypesAllowed] =
    useState<boolean>(false);
  const [invalidEndTime, setInvalidEndTime] = useState<boolean>(false);
  const [airports, setAirports] = useState<any[]>([]);
  useEffect(() => {
    props.setInvalidForm(
      !props.operationalNeed ||
        !props.airportId ||
        !props.rangeType ||
        !props.startTime ||
        !props.endTime
    );

    if (props.endTime < props.startTime) {
      setInvalidEndTime(true);
      props.setInvalidForm(true);
    } else {
      setInvalidEndTime(false);
    }
  }, [
    props.operationalNeed,
    props.airportId,
    props.rangeType,
    props.startTime,
    props.endTime,
  ]);
  useEffect(() => {
    setLoading(true);
  }, []);

  useEffect(() => {
    if (!auth.loading && auth.user) {
      if (
        auth.user.roles.includes(UserRole.Admin) ||
        auth.user.roles.includes(UserRole.AirportAdmin)
      ) {
        setAllRangeTypesAllowed(true);
      }

      if (
        auth.user.roles.includes(UserRole.AirportAdmin) ||
        auth.user.roles.includes(UserRole.AirportOfficer)
      ) {
        // Only the airport that admin/officer belongs to are allowed
        setLoading(true);
        api
          .getUserAirport()
          .then((res) => {
            setAirports([res.data]);
            props.setAirportId(res.data.id);
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      } else if (auth.user.roles.includes(UserRole.Admin)) {
        // All airports allowed
        setLoading(true);
        api
          .getAirports(1, 1000, "", "", "DESC", "")
          .then((res) => {
            setAirports(res.data.data);
            props.setAirportId(res.data.data[0].id);
          })
          .catch((err) => console.error(err))
          .finally(() => setLoading(false));
      }
    }
  }, [auth.loading]);

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  return (
    <div className="mt-4 sm:px-0 px-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-4">
          <label
            htmlFor="operational-need"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Operational Need
          </label>
          <div className="mt-2">
            <textarea
              rows={2}
              name="operational-need"
              id="operational-need"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
              value={props.operationalNeed}
              onChange={(e) => props.setOperationalNeed(e.target.value)}
              placeholder="Operational Need"
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <label
            htmlFor="pass-duration"
            className="block text-sm font-medium text-gray-700"
          >
            Airport{showAsteriskIfEmpty(props.airportId)}
          </label>
          <select
            id="airport"
            name="airport"
            autoComplete="airport"
            onChange={(e) => props.setAirportId(e.target.value)}
            value={props.airportId}
            disabled={loading || airports.length === 0}
            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
          >
            {airports.map((airport) => {
              return (
                <option value={airport.id}>
                  {airport.name} ({airport.timezone})
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-span-6 sm:col-span-4">
          <label
            htmlFor="pass-duration"
            className="block text-sm font-medium text-gray-700"
          >
            Pass Duration{showAsteriskIfEmpty(props.rangeType)}
          </label>
          <select
            id="pass-duration"
            name="pass-duration"
            autoComplete="pass-duration"
            onChange={(e) => props.setRangeType(e.target.value)}
            value={props.rangeType}
            disabled={!allRangeTypesAllowed}
            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
          >
            <option>24 Hours</option>
            <option>7 Days</option>
            <option>28 Days</option>
            <option>Custom</option>
          </select>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="start-time"
            className="block text-sm font-medium text-gray-700"
          >
            Start Time{showAsteriskIfEmpty(props.startTime)}
          </label>
          <div className="mt-1">
            <input
              id="start-time"
              name="start-time"
              type="datetime-local"
              autoComplete="new-start-time"
              onChange={(e) => props.setStartTime(e.target.value)}
              value={props.startTime}
              required
              step={60 * 15}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="end-time"
            className="block text-sm font-medium text-gray-700"
          >
            End Time{showAsteriskIfEmpty(props.endTime)}
          </label>
          <div className="mt-1">
            <input
              id="end-time"
              name="end-time"
              type="datetime-local"
              autoComplete="new-end-time"
              onChange={(e) => props.setEndTime(e.target.value)}
              value={props.endTime}
              required
              disabled={props.rangeType !== "Custom"}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:opacity-60"
            />
          </div>
        </div>
      </div>
      {invalidEndTime && (
        <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 sm:px-0 px-4 mt-4">
          <div className="my-1">
            Please set a valid end time after the start time to continue
          </div>
        </div>
      )}
    </div>
  );
};
