export const eRPRM_ResultType = {
  RPRM_ResultType_Empty: 0,
  RPRM_ResultType_RawImage: 1,
  RPRM_ResultType_FileImage: 2,
  RPRM_ResultType_MRZ_OCR_Extended: 3,
  RPRM_ResultType_BarCodes: 5,
  RPRM_ResultType_Graphics: 6,
  RPRM_ResultType_MRZ_TestQuality: 7,
  RPRM_ResultType_DocumentTypesCandidates: 8,
  RPRM_ResultType_ChosenDocumentTypeCandidate: 9,
  RPRM_ResultType_DocumentsInfoList: 10,
  RPRM_ResultType_OCRLexicalAnalyze: 15,
  RPRM_ResultType_RawUncroppedImage: 16,
  RPRM_ResultType_Visual_OCR_Extended: 17,
  RPRM_ResultType_BarCodes_TextData: 18,
  RPRM_ResultType_BarCodes_ImageData: 19,
  RPRM_ResultType_Authenticity: 20,
  RPRM_ResultType_EOSImage: 23,
  RPRM_ResultType_BayerImage: 24,
  RPRM_ResultType_MagneticStripe: 25,
  RPRM_ResultType_MagneticStripe_TextData: 26,
  RPRM_ResultType_FieldFileImage: 27,
  RPRM_ResultType_DatabaseCheck: 28,
  RPRM_ResultType_FingerprintTemplateISO: 29,
  RPRM_ResultType_InputImageQuality: 30,
  RPRM_ResultType_DeviceInfo: 31,
  RPRM_ResultType_LivePortrait: 32,
  RPRM_ResultType_Status: 33,
  RPRM_ResultType_Portrait_Comparison: 34,
  RPRM_ResultType_ExtPortrait: 35,
  RPRM_ResultType_Text: 36,
  RPRM_ResultType_Images: 37,
  RPRM_ResultType_FingerPrints: 38,
  RPRM_ResultType_BSI_XML_v2: 73,
  RPRM_ResultType_DocumentPosition: 85,
  RPRM_ResultType_BSI_XML: 92,
};

export const eRPRM_Lights = {
  RPRM_Light_OFF: 0,
  RPRM_Light_OVI: 1,
  RPRM_Light_White_Top: 2,
  RPRM_Light_White_Side: 4,
  RPRM_Light_White_Full: 6,
  RPRM_Light_IR_Top: 8,
  RPRM_Light_IR_Side: 16,
  RPRM_Light_IR_Full: 24,
  RPRM_Light_Transmitted: 32,
  RPRM_Light_Transmitted_IR: 64,
  RPRM_Light_UV: 128,
  RPRM_Light_IR_Luminescence: 256,
  RPRM_Light_AXIAL_White_Front: 512,
  RPRM_Light_AXIAL_White_Left: 1024,
  RPRM_Light_AXIAL_White_Right: 2048,
  RPRM_Light_AXIAL_White_Full: 3072,
  RPRM_Light_IR_Bottom: 4096,
  RPRM_Light_White_Bottom: 8192,
  RPRM_Light_AntiStokes: 65536,
  RPRM_Light_White_Front: 8388608,
  RPRM_Light_IR_Front: 16777216,
  RPRM_Light_White_Gray: 33554432,
  RPRM_Light_OVD: 67108864,
};

export const eRPRM_FieldVerificationResult = {
  RCF_Disabled: 0,
  RCF_Verified: 1,
  RCF_Not_Verified: 2,
  RCF_Compare_True: 3,
  RCF_Compare_False: 4,
};

export const eVisualFieldTypeStrings = {
  strDocumentClassCode: 0,
  strIssuingStateCode: 1,
  strDocumentNumber: 2,
  strDateofExpiry: 3,
  strDateofIssue: 4,
  strDateofBirth: 5,
  strPlaceofBirth: 6,
  strPersonalNumber: 7,
  strSurname: 8,
  strGivenNames: 9,
  strMothersName: 10,
  strNationality: 11,
  strSex: 12,
  strHeight: 13,
  strWeight: 14,
  strEyesColor: 15,
  strHairColor: 16,
  strAddress: 17,
  strDonor: 18,
  strSocialSecurityNumber: 19,
  strDLClass: 20,
  strDLEndorsed: 21,
  strDLRestrictionCode: 22,
  strDLUnder21Date: 23,
  strAuthority: 24,
  strSurnameAndGivenNames: 25,
  strNationalityCode: 26,
  strPassportNumber: 27,
  strInvitationNumber: 28,
  strVisaID: 29,
  strVisaClass: 30,
  strVisaSubClass: 31,
  strMRZString1: 32,
  strMRZString2: 33,
  strMRZString3: 34,
  strMRZType: 35,
  strOptionalData: 36,
  strDocumentClassName: 37,
  strIssuingStateName: 38,
  strPlaceOfIssue: 39,
  strOther: 50,
  strMRZStrings: 51,
  strNameSuffix: 52,
  strNamePrefix: 53,
  strDateofIssueCheckDigit: 55,
  strDocumentSeries: 56,
  strRegCertRegNumber: 57,
  strRegCertCarModel: 58,
  strRegCertCarColor: 59,
  strRegCertBodyNumber: 60,
  strRegCertCarType: 61,
  strRegCertMaxWeight: 62,
  strRegCertWeight: 63,
  strAddressArea: 64,
  strAddressState: 65,
  strAddressBuilding: 66,
  strAddressHouse: 67,
  strAddressFlat: 68,
  strPlaceofRegistration: 69,
  strDateofRegistration: 70,
  strResidentFrom: 71,
  strResidentUntil: 72,
  strAuthorityCode: 73,
  strPlaceofBirthArea: 74,
  strPlaceofBirthStateCode: 75,
  strAddressStreet: 76,
  strAddressCity: 77,
  strAddressJurisdictionCode: 78,
  strAddressPostalCode: 79,
  strDocumentNumberCheckDigit: 80,
  strDateofBirthCheckDigit: 81,
  strDateofExpiryCheckDigit: 82,
  strPersonalNumberCheckDigit: 83,
  strFinalCheckDigit: 84,
  strPassportNumberCheckDigit: 85,
  strInvitationNumberCheckDigit: 86,
  strVisaIDCheckDigit: 87,
  strSurnameAndGivenNamesCheckDigit: 88,
  strVisaValidUntilCheckDigit: 89,
  strPermitDLClass: 90,
  strPermitDateofExpiry: 91,
  strPermitIdentifier: 92,
  strPermitDateofIssue: 93,
  strPermitRestrictionCode: 94,
  strPermitEndorsed: 95,
  strIssueTimestamp: 96,
  strNumberofDuplicates: 97,
  strMedicalIndicatorCodes: 98,
  strNonResidentIndicator: 99,
  strVisaType: 100,
  strVisaValidFrom: 101,
  strVisaValidUntil: 102,
  strDurationofStay: 103,
  strNumberofEntries: 104,
  strUniqueCustomerIdentifier: 108,
  strCommercialVehicleCodes: 109,
  strAKADateofBirth: 110,
  strAKASocialSecurityNumber: 111,
  strAKAName: 112,
  strAKANameSuffix: 113,
  strAKANamePrefix: 114,
  strAKANamePrefixDbl: 115,
  strMailingAddressStreet: 116,
  strMailingAddressCity: 117,
  strMailingAddressJurisdictionCode: 118,
  strMailingAddressPostalCode: 119,
  strAuditInformation: 120,
  strInventoryNumber: 121,
  strRaceEthnicity: 122,
  strJurisdictionVehicleClass: 123,
  strJurisdictionEndorsementCode: 124,
  strJurisdictionRestrictionCode: 125,
  strFamilyName: 126,
  strGivenNamesRUS: 127,
  strVisaIDRUS: 128,
  strFathersName: 129,
  strFathersNameRUS: 130,
  strSurnameAndGivenNamesRUS: 131,
  strPlaceOfBirthRUS: 132,
  strAuthorityRUS: 133,
  strIssuingStateCodeNumeric: 134,
  strNationalityCodeNumeric: 135,
  strEnginePower: 136,
  strEngineVolume: 137,
  strChassisNumber: 138,
  strEngineNumber: 139,
  strEngineModel: 140,
  strVehicleCategory: 141,
  strIdentityCardNumber: 142,
  strControlNo: 143,
  strParrentsGivenNames: 144,
  strSecondSurname: 145,
  strMiddleName: 146,
  strRegCertVIN: 147,
  strRegCertVINCheckDigit: 148,
  strRegCertVINChecksum: 149,
  strLine1CheckDigit: 150,
  strLine2CheckDigit: 151,
  strLine3CheckDigit: 152,
  strLine1Checksum: 153,
  strLine2Checksum: 154,
  strLine3Checksum: 155,
  strRegCertRegNumberCheckDigit: 156,
  strRegCertRegNumberChecksum: 157,
  strRegCertVehicleITSCode: 158,
  strCAN: 159,
  strMaritalStatus: 160,
  strCompanyName: 161,
  strSpecialNotes: 162,
  strSurnameofSpose: 163,
  strTrackingNumber: 164,
  strBookletNumber: 165,
  strChildren: 166,
  strCopy: 167,
  strSerialNumber: 168,
  strDossierNumber: 169,
  strAKASurnameAndGivenNames: 170,
  strTerritorialValidity: 171,
  strMRZStringsWithCorrectCheckSums: 172,
  strDLCDLRestrictionCode: 173,
  strDLUnder18Date: 174,
  strDLRecordCreated: 175,
  strDLDuplicateDate: 176,
  strSequenceNumber: 177,
  strMilitaryBookNumber: 178,
  strDestination: 179,
  strBloodGroup: 180,
  // strSequenceNumber: 181,
  strRegCertBodyType: 182,
  strRegCertCarMark: 183,
  strTransactionNumber: 184,
  strAge: 185,
  strFolioNumber: 186,
  strVoterKey: 187,
  strAddressMunicipality: 188,
  strAddressLocation: 189,
  strSection: 190,
  strOCRNumber: 191,
  strFederalElections: 192,
  strReferenceNumber: 193,
  strOptionalDataChecksum: 194,
  strOptionalDataCheckDigit: 195,
  strVisaNumber: 196,
  strVisaNumberChecksum: 197,
  strVisaNumberCheckDigit: 198,
  strVoter: 199,
  strPreviousType: 200,
  strFieldFromMRZ: 220,
  strStatusDateofExpiry: 251,
  strBanknoteNumber: 252,
  strCSCCode: 253,
  strArtisticName: 254,
  strAcademicTitle: 255,
  strAdressCountry: 256,
  strAdressZipcode: 257,
  streIDResidencePermit1: 258,
  streIDResidencePermit2: 259,
  streIDPlaceOfBirthStreet: 260,
  streIDPlaceOfBirthCity: 261,
  streIDPlaceOfBirthState: 262,
  streIDPlaceOfBirthCountry: 263,
  streIDPlaceOfBirthZipcode: 264,
  strCDLClass: 265,
  strDLUnder19Date: 266,
  strWeightPound: 267,
  strLimitedDurationDocumentIndicator: 268,
  strEndorsementExpirationDate: 269,
  strRevisionDate: 270,
  strComplianceType: 271,
  strFamilyNameTruncation: 272,
  strFirstNameTruncation: 273,
  strMiddleNameTruncation: 274,
  strExamDate: 275,
  strOrganization: 276,
  strDepartment: 277,
  strPayGrade: 278,
  strRank: 279,
  strBenefitsNumber: 280,
  strSponsorService: 281,
  strSponsorStatus: 282,
  strSponsor: 283,
  strRelationship: 284,
  strUSCIS: 285,
  strCategory: 286,
  strConditions: 287,
  strIdentifier: 288,
  strConfiguration: 289,
  strDiscretionaryData: 290,
  strLine1OptionalData: 291,
  strLine2OptionalData: 292,
  strLine3OptionalData: 293,
  strEQVCode: 294,
  strALTCode: 295,
  strBinaryCode: 296,
  strPseudoCode: 297,
  strFee: 298,
  strStampNumber: 299,
  strSBHSecurityOptions: 300,
  strSBHIntegrityOptions: 301,
  strDateofCreation: 302,
  strValidityPeriod: 303,
  strPatronHeaderVersion: 304,
  strBDBType: 305,
  strBiometricType: 306,
  strBiometricSubtype: 307,
  strBiometricProductID: 308,
  strBiometricFormatOwner: 309,
  strBiometricFormatType: 310,
  strPhone: 311,
  strProfession: 312,
  strTitle: 313,
  strPersonalSummary: 314,
  strOtherValidID: 315,
  strCustodyInfo: 316,
  strOtherName: 317,
  strObservations: 318,
  strTax: 319,
  strDateofPersonalization: 320,
  strPersonalizationSN: 321,
  strOtherPersonName: 322,
  strPersonToNotifyDateofRecord: 323,
  strPersonToNotifyName: 324,
  strPersonToNotifyPhone: 325,
  strPersonToNotifyAddress: 326,
  strDSCertificateIssuer: 327,
  strDSCertificateSubject: 328,
  strDSCertificateValidFrom: 329,
  strDSCertificateValidTo: 330,
  strVRCDataObjectEntry: 331,
  strGNIBNumber: 340,
  strDeptNumber: 341,
  strTelexCode: 342,
  strAllergies: 343,
  strSpCode: 344,
  strCourtCode: 345,
  strCty: 346,
  strSponsorSSN: 347,
  strDoDNumber: 348,
  strMCNoviceDate: 349,
  strDUFNumber: 350,
  strAGY: 351,
  strPNRCode: 352,
  strFromAirportCode: 353,
  strToAirportCode: 354,
  strFlightNumber: 355,
  strDateofFlight: 356,
  strSeatNumber: 357,
  strDateofIssueBoardingPass: 358,
  strCCWUntil: 359,
  strReferenceNumberChecksum: 360,
  strReferenceNumberCheckDigit: 361,
  strRoomNumber: 362,
  strReligion: 363,
  strRemainderTerm: 364,
  strElectronicTicketIndicator: 365,
  strCompartmentCode: 366,
  strCheckInSequenceNumber: 367,
  strAirlineDesignatorofboardingpassissuer: 368,
  strAirlineNumericCode: 369,
  strTicketNumber: 370,
  strFrequentFlyerAirlineDesignator: 371,
  strFrequentFlyerNumber: 372,
  strFreeBaggageAllowance: 373,
  strPDF417Codec: 374,
  strIdentityCardNumberChecksum: 375,
  strIdentityCardNumberCheckDigit: 376,
  strVeteran: 377,
  strDLClassCodeA1From: 378,
  strDLClassCodeA1To: 379,
  strDLClassCodeA1Notes: 380,
  strDLClassCodeAFrom: 381,
  strDLClassCodeATo: 382,
  strDLClassCodeANotes: 383,
  strDLClassCodeBFrom: 384,
  strDLClassCodeBTo: 385,
  strDLClassCodeBNotes: 386,
  strDLClassCodeC1From: 387,
  strDLClassCodeC1To: 388,
  strDLClassCodeC1Notes: 389,
  strDLClassCodeCFrom: 390,
  strDLClassCodeCTo: 391,
  strDLClassCodeCNotes: 392,
  strDLClassCodeD1From: 393,
  strDLClassCodeD1To: 394,
  strDLClassCodeD1Notes: 395,
  strDLClassCodeDFrom: 396,
  strDLClassCodeDTo: 397,
  strDLClassCodeDNotes: 398,
  strDLClassCodeBEFrom: 399,
  strDLClassCodeBETo: 400,
  strDLClassCodeBENotes: 401,
  strDLClassCodeC1EFrom: 402,
  strDLClassCodeC1ETo: 403,
  strDLClassCodeC1ENotes: 404,
  strDLClassCodeCEFrom: 405,
  strDLClassCodeCETo: 406,
  strDLClassCodeCENotes: 407,
  strDLClassCodeD1EFrom: 408,
  strDLClassCodeD1ETo: 409,
  strDLClassCodeD1ENotes: 410,
  strDLClassCodeDEFrom: 411,
  strDLClassCodeDETo: 412,
  strDLClassCodeDENotes: 413,
  strDLClassCodeMFrom: 414,
  strDLClassCodeMTo: 415,
  strDLClassCodeMNotes: 416,
  strDLClassCodeLFrom: 417,
  strDLClassCodeLTo: 418,
  strDLClassCodeLNotes: 419,
  strDLClassCodeTFrom: 420,
  strDLClassCodeTTo: 421,
  strDLClassCodeTNotes: 422,
  strDLClassCodeAMFrom: 423,
  strDLClassCodeAMTo: 424,
  strDLClassCodeAMNotes: 425,
  strDLClassCodeA2From: 426,
  strDLClassCodeA2To: 427,
  strDLClassCodeA2Notes: 428,
  strDLClassCodeB1From: 429,
  strDLClassCodeB1To: 430,
  strDLClassCodeB1Notes: 431,
  strSurnameAtBirth: 432,
  strCivilStatus: 433,
  strNumberofSeats: 434,
  strNumberofStandingPlaces: 435,
  strMaxSpeed: 436,
  strFuelType: 437,
  strECEnvironmentalType: 438,
  strPowerWeightRatio: 439,
  strMaxMassofTrailerBraked: 440,
  strMaxMassofTrailerUnbraked: 441,
  strTransmissionType: 442,
  strTrailerHitch: 443,
  strAccompaniedby: 444,
  strPoliceDistrict: 445,
  strFirstIssueDate: 446,
  strPayloadCapacity: 447,
  strNumberofAxels: 448,
  strPermissibleAxleLoad: 449,
  strPrecinct: 450,
  strInvitedBy: 451,
  strPurposeOfEntry: 452,
  strSkinColor: 453,
  strComplexion: 454,
  strAirportFrom: 455,
  strAirportTo: 456,
  strAirlineName: 457,
  strAirlineNameFrequentFlyer: 458,
  strLicenseNumber: 459,
  strInTanks: 460,
  strExeptInTanks: 461,
  strFastTrack: 462,
  strOwner: 463,
  strMRZLinesICAORFID: 464,
  strNumberOfCardIssuance: 465,
  strNumberOfCardIssuanceChecksum: 466,
  strNumberOfCardIssuanceCheckDigit: 467,
  strCenturyDateOfBirth: 468,
  strDLClassCodeA3From: 469,
  strDLClassCodeA3To: 470,
  strDLClassCodeA3Notes: 471,
  strDLClassCodeC2From: 472,
  strDLClassCodeC2To: 473,
  strDLClassCodeC2Notes: 474,
  strDLClassCodeB2From: 475,
  strDLClassCodeB2To: 476,
  strDLClassCodeB2Notes: 477,
  strDLClassCodeD2From: 478,
  strDLClassCodeD2To: 479,
  strDLClassCodeD2Notes: 480,
  strDLClassCodeB2EFrom: 481,
  strDLClassCodeB2ETo: 482,
  strDLClassCodeB2ENotes: 483,
  strDLClassCodeGFrom: 484,
  strDLClassCodeGTo: 485,
  strDLClassCodeGNotes: 486,
  strDLClassCodeJFrom: 487,
  strDLClassCodeJTo: 488,
  strDLClassCodeJNotes: 489,
  strDLClassCodeLCFrom: 490,
  strDLClassCodeLCTo: 491,
  strDLClassCodeLCNotes: 492,
  strBankCardNumber: 493,
  strBankCardValidThru: 494,
  strTaxNumber: 495,
  strHealthNumber: 496,
  strGrandfatherName: 497,
  strSelecteeIndicator: 498,
  strMotherSurname: 499,
  strMotherGivenName: 500,
  strFatherSurname: 501,
  strFatherGivenName: 502,
  strMotherDateOfBirth: 503,
  strFatherDateOfBirth: 504,
  strMotherPersonalNumber: 505,
  strFatherPersonalNumber: 506,
  strMotherPlaceOfBirth: 507,
  strFatherPlaceOfBirth: 508,
  strMotherCountryOfBirth: 509,
  strFatherCountryOfBirth: 510,
  strDateFirstRenewal: 511,
  strDateSecondRenewal: 512,
  strPlaceOfExamination: 513,
  strApplicationNumber: 514,
  strVoucherNumber: 515,
  strAuthorizationNumber: 516,
  strFaculty: 517,
  strFormOfEducation: 518,
  strDNINumber: 519,
  strRetirementNumber: 520,
  strProfessionalIdNumber: 521,
  strAgeAtIssue: 522,
  strDLClassCodeBTPFrom: 524,
  strDLClassCodeBTPNotes: 525,
  strDLClassCodeBTPTo: 526,
  strDLClassCodeC3From: 527,
  strDLClassCodeC3Notes: 528,
  strDLClassCodeC3To: 529,
  strDLClassCodeEFrom: 530,
  strDLClassCodeENotes: 531,
  strDLClassCodeETo: 532,
  strDLClassCodeFA1From: 533,
  strDLClassCodeFA1Notes: 534,
  strDLClassCodeFA1To: 535,
  strDLClassCodeFAFrom: 536,
  strDLClassCodeFANotes: 537,
  strDLClassCodeFATo: 538,
  strDLClassCodeFBFrom: 539,
  strDLClassCodeFBNotes: 540,
  strDLClassCodeFBTo: 541,
  strDLClassCodeFFrom: 542,
  strDLClassCodeFNotes: 543,
  strDLClassCodeFTo: 544,
  strDLClassCodeG1From: 545,
  strDLClassCodeG1Notes: 546,
  strDLClassCodeG1To: 547,
  strDLClassCodeHFrom: 548,
  strDLClassCodeHNotes: 549,
  strDLClassCodeHTo: 550,
  strDLClassCodeIFrom: 551,
  strDLClassCodeINotes: 552,
  strDLClassCodeITo: 553,
  strDLClassCodeKFrom: 554,
  strDLClassCodeKNotes: 555,
  strDLClassCodeKTo: 556,
  strDLClassCodeLKFrom: 557,
  strDLClassCodeLKNotes: 558,
  strDLClassCodeLKTo: 559,
  strDLClassCodeNFrom: 560,
  strDLClassCodeNNotes: 561,
  strDLClassCodeNTo: 562,
  strDLClassCodeSFrom: 563,
  strDLClassCodeSNotes: 564,
  strDLClassCodeSTo: 565,
  strDLClassCodeTBFrom: 566,
  strDLClassCodeTBNotes: 567,
  strDLClassCodeTBTo: 568,
  strDLClassCodeTMFrom: 569,
  strDLClassCodeTMNotes: 570,
  strDLClassCodeTMTo: 571,
  strDLClassCodeTRFrom: 572,
  strDLClassCodeTRNotes: 573,
  strDLClassCodeTRTo: 574,
  strDLClassCodeTVFrom: 575,
  strDLClassCodeTVNotes: 576,
  strDLClassCodeTVTo: 577,
  strDLClassCodeVFrom: 578,
  strDLClassCodeVNotes: 579,
  strDLClassCodeVTo: 580,
  strDLClassCodeWFrom: 581,
  strDLClassCodeWNotes: 582,
  strDLClassCodeWTo: 583,
  strURL: 584,
  strCaliber: 585,
  strModel: 586,
  strMake: 587,
  strNumberOfCylinders: 588,
  strSurnameOfHusbandAfterRegistration: 589,
  strSurnameOfWifeAfterRegistration: 590,
  strDateOfBirthOfWife: 591,
  strDateOfBirthOfHusband: 592,
  strCitizenshipOfFirstPerson: 593,
  strCitizenshipOfSecondPerson: 594,
  strCVV: 595,
  strDateOfInsuranceExpiry: 596,
  strMortgageBy: 597,
  strOldDocumentNumber: 598,
  strOldDateOfIssue: 599,
  strOldPlaceOfIssue: 600,
  strDLClassCodeLRFrom: 601,
  strDLClassCodeLRTo: 602,
  strDLClassCodeLRNotes: 603,
  strDLClassCodeMRFrom: 604,
  strDLClassCodeMRTo: 605,
  strDLClassCodeMRNotes: 606,
  strDLClassCodeHRFrom: 607,
  strDLClassCodeHRTo: 608,
  strDLClassCodeHRNotes: 609,
  strDLClassCodeHCFrom: 610,
  strDLClassCodeHCTo: 611,
  strDLClassCodeHCNotes: 612,
  strDLClassCodeMCFrom: 613,
  strDLClassCodeMCTo: 614,
  strDLClassCodeMCNotes: 615,
  strDLClassCodeREFrom: 616,
  strDLClassCodeRETo: 617,
  strDLClassCodeRENotes: 618,
  strDLClassCodeRFrom: 619,
  strDLClassCodeRTo: 620,
  strDLClassCodeRNotes: 621,
  strDLClassCodeCAFrom: 622,
  strDLClassCodeCATo: 623,
  strDLClassCodeCANotes: 624,
  strCitizenshipStatus: 625,
  strMilitaryServiceFrom: 626,
  strMilitaryServiceTo: 627,
};
