/* eslint-disable @typescript-eslint/no-explicit-any */
import { hubConnection } from "signalr-no-jquery";
import axios from "axios";
import RegulaEvents from "./events";
import { trimChar } from "./utils";

let serviceUrl: string;

/*=====/Settings=====*/
export function getPropertyValue(
  propertyName: string,
  successFunction: (data: unknown) => void
) {
  get(
    `/Settings/GetPropertyValue?propertyName=${propertyName}`,
    successFunction
  );
}

export function setPropertyValue(
  propertyName: string,
  value: unknown,
  successFunction: (data: unknown) => void
) {
  post(
    `/Settings/SetPropertyValue?propertyName=${propertyName}`,
    successFunction,
    value
  );
}

/*=====/Methods=====*/

export function appendImage(
  aImage: string,
  aFormat: string,
  aLightType: string,
  aPageIndex: number,
  successFunction: (data: unknown) => void
) {
  const img = aImage.replace("data:image/jpeg;base64,", "");
  post(
    `/Methods/AppendImage?AFormat=${aFormat}&ALightType=${aLightType}&APageIndex=${aPageIndex}`,
    successFunction,
    img
  );
}

export function appendImageFromFile(
  aFileName: string,
  aLightType: string,
  aPageIndex: number,
  successFunction: (data: unknown) => void
) {
  post(
    `/Methods/AppendImageFromFile?AFileName=${aFileName}&ALightType=${aLightType}&APageIndex=${aPageIndex}`,
    successFunction,
    null
  );
}

export function batteryStatusByIdx(
  index: number,
  successFunction: (data: unknown) => void
) {
  get(`/Methods/BatteryStatusByIdx?index=${index}`, successFunction);
}

export function calibrate(successFunction: (data: unknown) => void) {
  post("/Methods/Calibrate", successFunction, null);
}

export function cancelOpticalOperation(
  successFunction: (data: unknown) => void
) {
  post("/Methods/CancelOpticalOperation", successFunction, null);
}

export function checkReaderImageLight(
  index: number,
  successFunction: (data: unknown) => void
) {
  get(`/Methods/CheckReaderImageLight?AIdx=${index}`, successFunction);
}

export function checkReaderImagePageIndex(index: string, successFunction: any) {
  get("/Methods/CheckReaderImagePageIndex?AIdx=" + index, successFunction);
}

export function checkReaderResult(
  type: string,
  index: string,
  output: string,
  param: string,
  successFunction: any
) {
  get(
    "/Methods/CheckReaderResult?AType=" +
      type +
      "&AIdx=" +
      index +
      "&AOutput=" +
      output +
      "&AParam=" +
      param,
    successFunction
  );
}

export function checkReaderResultFromList(
  container: string,
  output: string,
  param: string,
  successFunction: any
) {
  get(
    "/Methods/CheckReaderResultFromList?AContainer=" +
      container +
      "&AOutput=" +
      output +
      "&AParam=" +
      param,
    successFunction
  );
}

export function checkReaderResultXML(
  type: string,
  index: string,
  output: string,
  successFunction: any
) {
  get(
    "/Methods/CheckReaderResultXML?AType=" +
      type +
      "&AIdx=" +
      index +
      "&AOutput=" +
      output,
    successFunction
  );
}

export function checkRFIDResult(
  type: string,
  output: string,
  param: string,
  successFunction: any
) {
  get(
    "/Methods/CheckRFIDResult?AType=" +
      type +
      "&AOutput=" +
      output +
      "&AParam=" +
      param,
    successFunction
  );
}

export function checkRFIDResultFromList(
  container: string,
  output: string,
  param: string,
  successFunction: any
) {
  get(
    "/Methods/CheckRFIDResultFromList?AContainer=" +
      container +
      "&AOutput=" +
      output +
      "&AParam=" +
      param,
    successFunction
  );
}

export function checkRFIDResultXML(
  type: string,
  output: string,
  successFunction: any
) {
  get(
    "/Methods/CheckRFIDResultXML?AType=" + type + "&AOutput=" + output,
    successFunction
  );
}

export function checkUpdates(successFunction: any) {
  post("/Methods/CheckUpdates", successFunction);
}

export function clearResults(successFunction: any) {
  post("/Methods/ClearResults", successFunction);
}

export function connect(successFunction: any) {
  post("/Methods/Connect", successFunction);
}

export function disconnect(successFunction: any) {
  post("/Methods/Disconnect", successFunction);
}

export function doProcessImage(AFilename: string, successFunction: any) {
  post("/Methods/DoProcessImage?AFilename=" + AFilename, successFunction);
}

export function doProcessImages(AFolder: string, successFunction: any) {
  post("/Methods/DoProcessImages?AFolder=" + AFolder, successFunction);
}

export function get_AvailableDevices(index: string, successFunction: any) {
  get("/Methods/get_AvailableDevices?Index=" + index, successFunction);
}

export function get_AvailableGraphicFormats(
  index: string,
  successFunction: any
) {
  get("/Methods/get_AvailableGraphicFormats?Index=" + index, successFunction);
}

export function get_AvailableLanguages(index: string, successFunction: any) {
  get("/Methods/get_AvailableLanguages?Index=" + index, successFunction);
}

export function getBarcodeModuleCount(successFunction: any) {
  get("/Methods/GetBarcodeModuleCount", successFunction);
}

export function getBarcodeModuleDataByIdx(index: string, successFunction: any) {
  get("/Methods/GetBarcodeModuleDataByIdx?AIdx=" + index, successFunction);
}

export function getGraphicFieldByTypeAndSource(
  type: string,
  source: string,
  successFunction: any
) {
  get(
    "/Methods/GetGraphicFieldByTypeAndSource?AType=" +
      type +
      "&ASourceType=" +
      source,
    successFunction
  );
}

export function getImages(successFunction: any) {
  get("/Methods/GetImages", successFunction);
}

export function getReaderBitmapImage(index: string, successFunction: any) {
  get("/Methods/GetReaderBitmapImage?AIdx=" + index, successFunction);
}

export function getReaderBitmapImageByLightIndex(
  light: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderBitmapImageByLightIndex?ALight=" + light,
    successFunction
  );
}

export function getReaderBitmapImageByLightIndexAndPageIndex(
  light: string,
  page: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderBitmapImageByLightIndexAndPageIndex?ALight=" +
      light +
      "&APageIndex=" +
      page,
    successFunction
  );
}

export function getReaderEOSBitmapImage(index: string, successFunction: any) {
  get("/Methods/GetReaderEOSBitmapImage?AIdx=" + index, successFunction);
}

export function getReaderEOSBitmapImageByLightIndex(
  light: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderEOSBitmapImageByLightIndex?ALight=" + light,
    successFunction
  );
}

export function getReaderEOSBitmapImageByLightIndexAndPageIndex(
  light: string,
  page: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderEOSBitmapImageByLightIndexAndPageIndex?ALight=" +
      light +
      "&APageIndex=" +
      page,
    successFunction
  );
}

export function getReaderFileImage(index: string, successFunction: any) {
  get("/Methods/GetReaderFileImage?AIdx=" + index, successFunction);
}

export function getReaderFileImageByLightIndex(
  light: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderFileImageByLightIndex?ALight=" + light,
    successFunction
  );
}

export function getReaderFileImageByLightIndexAndPageIndex(
  light: string,
  page: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderFileImageByLightIndexAndPageIndex?ALight=" +
      light +
      "&APageIndex=" +
      page,
    successFunction
  );
}

export function getReaderGraphicsBitmapByFieldType(
  type: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderGraphicsBitmapByFieldType?AType=" + type,
    successFunction
  );
}

export function getReaderGraphicsFileImageByFieldType(
  type: string,
  successFunction: any
) {
  get(
    "/Methods/GetReaderGraphicsFileImageByFieldType?AType=" + type,
    successFunction
  );
}

export function getRFIDDGRawData(type: string, successFunction: any) {
  get("/Methods/GetRFIDDGRawData?AType=" + type, successFunction);
}

export function getRFIDGraphicsBitmapByFieldType(
  type: string,
  successFunction: any
) {
  get(
    "/Methods/GetRFIDGraphicsBitmapByFieldType?AType=" + type,
    successFunction
  );
}

export function getRFIDGraphicsFileImageByFieldType(
  type: string,
  successFunction: any
) {
  get(
    "/Methods/GetRFIDGraphicsFileImageByFieldType?AType=" + type,
    successFunction
  );
}

export function getSnapshot(light: string, mode: string, successFunction: any) {
  get(
    "/Methods/GetSnapshot?ALight=" + light + "&AMode=" + mode,
    successFunction
  );
}

export function getTextFieldByType(type: string, successFunction: any) {
  get("/Methods/GetTextFieldByType?AType=" + type, successFunction);
}

export function getTextFieldByTypeAndLCID(
  type: string,
  lcid: string,
  successFunction: any
) {
  get(
    "/Methods/GetTextFieldByTypeAndLCID?AType=" + type + "&ALCID=" + lcid,
    successFunction
  );
}

export function getTextFieldByTypeAndSource(
  type: string,
  source: string,
  originalSource: string,
  lcid: string,
  successFunction: any
) {
  get(
    "/Methods/GetTextFieldByTypeAndSource?AType=" +
      type +
      "&ASourceType=" +
      source +
      "&AOriginalSource=" +
      originalSource +
      "&ALCID=" +
      lcid,
    successFunction
  );
}

export function Hide(successFunction: any) {
  post("/Methods/Hide", successFunction);
}

export function hideResultPopup(successFunction: any) {
  post("/Methods/HideResultPopup", successFunction);
}

export function isReaderResultTypeAvailable(
  type: string,
  successFunction: any
) {
  get("/Methods/IsReaderResultTypeAvailable?AType=" + type, successFunction);
}

export function led(
  color: string,
  rate: string,
  index: string,
  successFunction: any
) {
  post(
    "/Methods/LED?AColor=" + color + "&ABlinkRate=" + rate + "&AIndex=" + index,
    successFunction
  );
}

export function playSound(ATimes: string, successFunction: any) {
  post("/Methods/PlaySound?ATimes=" + ATimes, successFunction);
}

export function refreshPACertStore(successFunction: any) {
  post("/Methods/RefreshPACertStore", successFunction);
}

export function reportCurrentSampleIssue(successFunction: any) {
  post("/Methods/ReportCurrentSampleIssue", successFunction);
}

export function rfidCancelReading(successFunction: any) {
  post("/Methods/RFIDCancelReading", successFunction);
}

export function setActiveLights(activeLights: string, successFunction: any) {
  post("/Methods/SetActiveLights?ALights=" + activeLights, successFunction);
}

export function getActiveLights(successFunction: any) {
  get("/Methods/GetActiveLights", successFunction);
}

export function saveConfig(successFunction: any) {
  post("/Methods/SaveConfig", successFunction);
}

export function show(successFunction: any) {
  post("/Methods/Show", successFunction);
}

export function waitAndReadRFID(successFunction: any) {
  post("/Methods/WaitAndReadRFID", successFunction);
}

export function ejectDocument(successFunction: any) {
  post("/Methods/EjectDocument", successFunction);
}

export function addActiveLight(ALight: string, successFunction: any) {
  post("/Methods/AddActiveLight?ALight=" + ALight, successFunction);
}

// export function checkReaderResultJSON(
//   type: string,
//   index: string,
//   output: string
// ) {
//   get(
//     "/Methods/CheckReaderResultJSON?AType=" +
//       type +
//       "&AIdx=" +
//       index +
//       "&AOutput=" +
//       output,
//     successFunction
//   );
// }

export function getLED(index: string, successFunction: any) {
  get("/Methods/GetLED?idx=" + index, successFunction);
}

export function checkRFIDResultJSON(
  type: string,
  output: string,
  successFunction: any
) {
  get(
    "/Methods/CheckRFIDResultJSON?AType=" + type + "&AOutput=" + output,
    successFunction
  );
}

export function appendRFIDData(data: any, type: string, successFunction: any) {
  post("/Methods/AppendPortrait?AType=" + type, successFunction, data);
}

export function appendRFIDDataFromFile(
  filename: any,
  type: string,
  successFunction: any
) {
  post(
    "/Methods/AppendRFIDDataFromFile?AType=" + type,
    successFunction,
    filename
  );
}

export function comparePortraits(successFunction: any) {
  post("/Methods/ComparePortraits", successFunction);
}

export function customProcess(params: any, successFunction: any) {
  post("/Methods/CustomProcess", successFunction, params);
}

export function doLiveFaceCapture(successFunction: any) {
  post("/Methods/DoLiveFaceCapture", successFunction);
}

export function pkdAddResource(
  type: string,
  data: any,
  name: string,
  autorefresh: string,
  successFunction: any
) {
  post(
    "/Methods/PKDAddResource?AType=" +
      type +
      "&AName=" +
      name +
      "&AutoRefresh=" +
      autorefresh,
    successFunction,
    data
  );
}

export function pkdAddResourceFromFile(
  type: string,
  filename: any,
  autorefresh: string,
  successFunction: any
) {
  post(
    "/Methods/PKDAddResourceFromFile?AType=" +
      type +
      "&AutoRefresh=" +
      autorefresh,
    successFunction,
    filename
  );
}

export function pkdClearPAStore(successFunction: any) {
  post("/Methods/PKDClearPAStore", successFunction);
}

export function pkdClearTAStore(successFunction: any) {
  post("/Methods/PKDClearTAStore", successFunction);
}

export function pkdRefreshPAStore(successFunction: any) {
  post("/Methods/PKDRefreshPAStore", successFunction);
}

export function pkdRefreshTAStore(successFunction: any) {
  post("/Methods/PKDRefreshTAStore", successFunction);
}

export function showPrintDialog(successFunction: any) {
  post("/Methods/ShowPrintDialog", successFunction);
}

export function liveCameraDevices(index: string, successFunction: any) {
  get("/Methods/LiveCameraDevices?Index=" + index, successFunction);
}

/*=====/Methods (SPECIAL)=====*/

export function shutdownComputer(doRestart: any, successFunction: any) {
  post("/Methods/ShutdownComputer", successFunction, doRestart);
}

export function restartSdk(successFunction: any) {
  post("/Methods/RestartSdk", successFunction);
}

export function getServiceVersion(successFunction: any) {
  post("/Methods/GetServiceVersion", successFunction);
}

export function getSystemDateTime(successFunction: any) {
  get("/Methods/GetSystemDateTime", successFunction);
}

export function setSystemDateTime(value: any, successFunction: any) {
  post("/Methods/SetSystemDateTime", successFunction, value);
}

export function notifyRfidRequestHandled(successFunction: any) {
  post("/Events/NotifyRfidRequestHandled", successFunction);
}

export function notifyCalibrationHandled(successFunction: any) {
  post("/Events/NotifyCalibrationHandled", successFunction);
}

export function notifyPortraitRequestHandled(successFunction: any) {
  post("/Events/NotifyPortraitRequestHandled", successFunction);
}

/*=====EVENTS=====*/
export function _toConsumableArray(arr: any) {
  return (
    _arrayWithoutHoles(arr) ||
    _iterableToArray(arr) ||
    _unsupportedIterableToArray(arr, undefined) ||
    _nonIterableSpread()
  );
}

export function _nonIterableSpread() {
  throw new TypeError(
    "Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."
  );
}

export function _unsupportedIterableToArray(o: any, minLen: any) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  let n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n))
    return _arrayLikeToArray(o, minLen);
}

export function _iterableToArray(iter: any) {
  if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter))
    return Array.from(iter);
}

export function _arrayWithoutHoles(arr: any) {
  if (Array.isArray(arr)) return _arrayLikeToArray(arr, undefined);
}

export function _arrayLikeToArray(
  arr: string | any[],
  len: number | null | undefined
) {
  if (len == null || len > arr.length) len = arr.length;
  const arr2 = new Array(len);
  for (let i = 0; i < len; i++) {
    arr2[i] = arr[i];
  }
  return arr2;
}

// Initialize Regula Reader
export function initRegulaReader(url: string, isFallback: boolean) {
  let signalRUrl: string = trimChar(url, "/");
  serviceUrl = url;

  if (isFallback) {
    signalRUrl += "notifications";
  }
  try {
    const connection = hubConnection(signalRUrl);

    const hubProxy = connection.createHubProxy("EventsHub");

    hubProxy.on(
      "onNotificationOptical",
      function (aCode: string, aValue: string) {
        if (RegulaEvents.onNotificationOpticalCallback != null)
          RegulaEvents.onNotificationOpticalCallback(aCode, aValue);
      }
    );

    hubProxy.on("onImageReady", function (aLight: string, aPageIndex: number) {
      if (RegulaEvents.onImageReadyCallback != null)
        RegulaEvents.onImageReadyCallback(aLight, aPageIndex);
    });

    hubProxy.on("onNotificationRFID", function (aCode: string, aValue: string) {
      if (RegulaEvents.onNotificationRFIDCallback != null)
        RegulaEvents.onNotificationRFIDCallback(aCode, aValue);
    });

    hubProxy.on("onProcessingFinished", function () {
      if (RegulaEvents.onProcessingFinishedCallback != null)
        RegulaEvents.onProcessingFinishedCallback();
    });

    hubProxy.on("onProcessingStarted", function () {
      if (RegulaEvents.onProcessingStartedCallback != null)
        RegulaEvents.onProcessingStartedCallback();
    });

    hubProxy.on("onResultReady", function (aType: string) {
      if (RegulaEvents.onResultReadyCallback != null)
        RegulaEvents.onResultReadyCallback(aType);
    });

    hubProxy.on(
      "onResultReadyXML",
      function (aType: string, resultXML: string) {
        if (RegulaEvents.onResultReadyXMLCallback != null)
          RegulaEvents.onResultReadyXMLCallback(aType, resultXML);
      }
    );

    hubProxy.on("onRFIDRequest", function (requestXML: string) {
      if (RegulaEvents.onRFIDRequestCallback != null)
        RegulaEvents.onRFIDRequestCallback(requestXML);
      else notifyRfidRequestHandled(null);
    });

    hubProxy.on("onSystemNotification", function (code: string, value: string) {
      if (RegulaEvents.onSystemNotificationCallback != null)
        RegulaEvents.onSystemNotificationCallback(code, value);
    });

    hubProxy.on("onExtPortraitRequest", function () {
      if (RegulaEvents.onExtPortraitRequestCallback != null)
        RegulaEvents.onExtPortraitRequestCallback();
      else notifyPortraitRequestHandled(null);
    });

    connection
      .start({ transport: "longPolling" })
      .done(() => {
        console.info("Connected to Regula scanner hub");
      })
      .fail(() => {
        alert("Connection to Regula SDK Service failed!");
      });
  } catch (error) {
    console.error("Error:", error);
    if (!isFallback) {
      initRegulaReader(signalRUrl, true);
    } else {
      alert("Connection to Regula SDK Service failed!");
    }
  }
}

// Utility export function to make POST requests
export function post(
  url: string,
  successFunction: (data: unknown) => void,
  data?: unknown
) {
  axios
    .post(serviceUrl + url, data, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      if (successFunction != null) successFunction(response.data);
    });
}

// Utility export function to make GET requests
export function get(url: string, successFunction: (data: unknown) => void) {
  axios.get(serviceUrl + url).then((response) => {
    if (successFunction != null) successFunction(response.data);
  });
}
