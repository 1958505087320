import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { UsersIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "../../components/SearchForm/SearchForm";
import Sidebar from "../../components/Sidebar/Sidebar";
import SortButton from "../../components/SortButton/SortButton";
import api from "../../utils/helpers/api";
import { UserRole } from "../../utils/types";
import { useAuthToken } from "../../hooks/useAuthToken";
import { usePageInitialization } from "../../hooks/usePageInitialization";

export default function UserManagement() {
  const { isInitiated } = usePageInitialization();
  const navigate = useNavigate();
  const [users, setUsers] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");

  useAuthToken();

  useEffect(() => {
    if (!isInitiated) return;
    fetchUsers();
  }, [pageNumber, searchQuery, searchField, sortField, sortOrder, isInitiated]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const users = await api.getUsers(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField
      );
      if (users.status === 200 && users.data.data) {
        setTotalCount(users.data.totalCount);
        setUsers(users.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} users of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UsersIcon className="h-6 w-6 mr-1.5" />
                    User Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in Visitor Pass including their
                    details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    onClick={() => navigate("/user-management/add")}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500">
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add user
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchUsers()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-stone-600">
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                <SearchForm
                  searchOptions={[
                    { name: "All", value: "all" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Username", value: "username" },
                  ]}
                  updateSearchQueryAndField={(
                    newSearchQuery,
                    newSearchField
                  ) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Created At
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Username
                                <SortButton
                                  active={sortField === "username"}
                                  sortOrder={sortOrder}
                                  sortField={"username"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Role
                                <SortButton
                                  active={sortField === "role"}
                                  sortOrder={sortOrder}
                                  sortField={"role"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {users.map((user) => (
                            <tr
                              key={user.username}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate("/user-management/edit/" + user.id)
                              }>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(user.createdAt).toDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.username}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.firstName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.roles.map((role: UserRole) => {
                                  if (role === UserRole.Admin) {
                                    return (
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                        Admin
                                      </div>
                                    );
                                  } else if (role === UserRole.AirportAdmin) {
                                    return (
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                                        Airport Admin
                                      </div>
                                    );
                                  } else if (role === UserRole.AirportOfficer) {
                                    return (
                                      <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-stone-100 text-stone-800">
                                        Airport Officer
                                      </div>
                                    );
                                  }
                                })}
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link to={"/user-management/edit/" + user.id}>
                                  <div className="text-amber-600 hover:text-amber-900">
                                    Edit
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <p className="text-sm text-gray-700">
                              Showing{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber - rowsPerPage + 1}
                              </span>{" "}
                              to{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber > totalCount
                                  ? totalCount
                                  : rowsPerPage * pageNumber}
                              </span>{" "}
                              of{" "}
                              <span className="font-medium">{totalCount}</span>{" "}
                              results
                            </p>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results
                            </p>
                          )}
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination">
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "border-gray-300 text-gray-500 hover:bg-gray-50" */}
                            {createPageNumbers()}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={
                                rowsPerPage * pageNumber > totalCount || loading
                              }
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
