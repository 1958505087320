import {
  BanIcon,
  ChatAltIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentRemoveIcon,
  IdentificationIcon,
  PrinterIcon,
  RefreshIcon,
  TableIcon,
  UserAddIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PlusIcon, TicketIcon } from "@heroicons/react/solid";
import { AxiosResponse } from "axios";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SearchForm from "../../components/SearchForm/SearchForm";
import Sidebar from "../../components/Sidebar/Sidebar";
import SortButton from "../../components/SortButton/SortButton";
import { useAuth } from "../../utils/auth/auth";
import api from "../../utils/helpers/api";
import { format } from "date-fns";
import { classNames } from "../../utils/helpers/misc";
import BulkSMSModal from "../../components/BulkSMSModal/BulkSMSModal";
import { Transition } from "@headlessui/react";
import ExportCSVModal from "../../components/ExportCSVModal/ExportCSVModal";
import DateRangePicker from "../../components/DateRangePicker/DateRangePicker";
import { useAuthToken } from "../../hooks/useAuthToken";
import { usePageInitialization } from "../../hooks/usePageInitialization";

export default function VisitorPassRequests() {
  const { isInitiated } = usePageInitialization();
  const navigate = useNavigate();
  const params = useParams();
  const auth = useAuth();
  const [exportCsvModalOpen, setExportCsvModalOpen] = useState<boolean>(false);
  const [exportSuccess, setExportSuccess] = useState<boolean>(false);
  const [exportError, setExportError] = useState<boolean>(false);
  const [visitor, setVisitor] = useState<any>();
  const [visitorPassRequests, setVisitorPassRequests] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [airportName, setAirportName] = useState<string>("...");
  const [bulkSmsModalOpen, setBulkSmsModalOpen] = useState<boolean>(false);
  const [sendSuccess, setSendSuccess] = useState<boolean>(false);
  const [sendError, setSendError] = useState<boolean>(false);
  const checkbox = useRef();
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState<any>(false);
  const [selectedVpRequests, setSelectedVpRequests] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  useAuthToken();

  useLayoutEffect(() => {
    const isIndeterminate =
      selectedVpRequests.length > 0 &&
      selectedVpRequests.length < visitorPassRequests.length;
    setChecked(
      selectedVpRequests.length !== 0 &&
        selectedVpRequests.length === visitorPassRequests.length
    );
    setIndeterminate(isIndeterminate);
    (checkbox.current! as any).indeterminate = isIndeterminate;
  }, [selectedVpRequests, loading]);

  function toggleAll() {
    setSelectedVpRequests(checked || indeterminate ? [] : visitorPassRequests);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  }

  useEffect(() => {
    if (!auth.loading && auth.user) {
      api.updateClient(auth.token);
      if (params.airportId) {
        api
          .getAirportById(params.airportId)
          .then((res) => {
            setAirportName(res.data.name);
          })
          .catch((err) => {
            console.error("Error fetching airport name - ", err);
          });
      }
    }
  }, [auth.user]);

  useEffect(() => {
    if (!isInitiated) return;
    fetchVisitorPassRequests();
  }, [
    pageNumber,
    searchQuery,
    searchField,
    sortOrder,
    sortField,
    status,
    startDate,
    endDate,
    isInitiated,
  ]);

  const fetchVisitorPassRequests = async () => {
    setLoading(true);
    try {
      let vpRequests: AxiosResponse<any, any>;
      if (params.visitorId) {
        vpRequests = await api.getVisitorPassRequestsForVisitor(
          pageNumber,
          rowsPerPage,
          searchQuery,
          searchField,
          sortOrder,
          sortField,
          params.visitorId,
          status
        );

        const visitor = await api.getVisitorById(params.visitorId);
        setVisitor(visitor.data);
      } else if (params.airportId) {
        vpRequests = await api.getVisitorPassRequestsForAirport(
          pageNumber,
          rowsPerPage,
          searchQuery,
          searchField,
          sortOrder,
          sortField,
          params.airportId,
          status
        );
      } else {
        vpRequests = await api.getVisitorPassRequests(
          pageNumber,
          rowsPerPage,
          searchQuery,
          searchField,
          sortOrder,
          sortField,
          status,
          startDate,
          endDate
        );
      }

      if (vpRequests.status === 200 && vpRequests.data.data) {
        setTotalCount(vpRequests.data.totalCount);
        setVisitorPassRequests(vpRequests.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} visitorPassRequests of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const tabs = [
    {
      name: "All",
      onClick: () => setStatus(""),
      icon: IdentificationIcon,
      current: status === "",
    },
    {
      name: "Active",
      onClick: () => setStatus("active"),
      icon: CheckCircleIcon,
      current: status === "active",
    },
    {
      name: "Requested",
      onClick: () => setStatus("1"),
      icon: UserAddIcon,
      current: status === "1",
    },
    {
      name: "Approved",
      onClick: () => setStatus("2"),
      icon: CheckIcon,
      current: status === "2",
    },
    {
      name: "Rejected",
      onClick: () => setStatus("3"),
      icon: XIcon,
      current: status === "3",
    },
    {
      name: "Expired",
      onClick: () => setStatus("4"),
      icon: DocumentRemoveIcon,
      current: status === "4",
    },
    {
      name: "Cancelled",
      onClick: () => setStatus("5"),
      icon: BanIcon,
      current: status === "5",
    },
    {
      name: "Printed",
      onClick: () => setStatus("6"),
      icon: PrinterIcon,
      current: status === "6",
    },
  ];

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <TicketIcon className="h-6 w-6 mr-1.5" />
                    <span>
                      Visitor Pass Requests{" "}
                      <span>
                        {params.visitorId && visitor && (
                          <em className="ml-1 text-gray-700">
                            (for visitor {visitor.email})
                          </em>
                        )}
                      </span>
                      <span>
                        {params.airportId && airportName && (
                          <em className="ml-1 text-gray-700">
                            (for {airportName})
                          </em>
                        )}
                      </span>
                    </span>
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the visitor pass requests{" "}
                    {params.visitorId && "made by the user"}
                    {params.airportId && "made for the airport"} in Visitor Pass
                    including their details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row sm:space-x-2">
                  <button
                    onClick={() => navigate("/visitor-pass-requests/add")}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-amber-500">
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Create Pass
                  </button>
                  {status !== "" && (
                    <button
                      onClick={() => setBulkSmsModalOpen(true)}
                      type="button"
                      disabled={loading}
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-blue-500">
                      <ChatAltIcon className="h-5 w-5 mr-1.5" />
                      Bulk SMS
                    </button>
                  )}
                  <button
                    onClick={() => setExportCsvModalOpen(true)}
                    type="button"
                    disabled={loading}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-blue-500">
                    <TableIcon className="h-5 w-5 mr-1.5" />
                    Export
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchVisitorPassRequests()}
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600">
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-col">
                <div className="mb-4">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full rounded-md border-gray-300 focus:border-amber-500 focus:ring-amber-500"
                      defaultValue={tabs.find((tab) => tab.current)!.name}
                      disabled={loading}>
                      {tabs.map((tab) => (
                        <option
                          disabled={loading}
                          onClick={tab.onClick}
                          key={tab.name}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <button
                            key={tab.name}
                            disabled={loading}
                            onClick={tab.onClick}
                            className={classNames(
                              tab.current
                                ? "border-amber-500 text-amber-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer disabled:opacity-60 disabled:hover:border-transparent disabled:hover:text-gray-500"
                            )}
                            aria-current={tab.current ? "page" : undefined}>
                            <tab.icon
                              className={classNames(
                                tab.current
                                  ? "text-amber-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            <span>{tab.name}</span>
                          </button>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <DateRangePicker
                  updateDates={(newStartDate, newEndDate) => {
                    setStartDate(newStartDate);
                    setEndDate(newEndDate);
                  }}
                  disabled={loading}
                />
                <SearchForm
                  searchOptions={[
                    { name: "All", value: "all" },
                    { name: "Email", value: "email" },
                    { name: "Company", value: "company" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                  ]}
                  updateSearchQueryAndField={(
                    newSearchQuery,
                    newSearchField
                  ) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="relative px-7 sm:w-12 sm:px-6">
                              <input
                                type="checkbox"
                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                                ref={checkbox as any}
                                checked={checked}
                                onChange={toggleAll}
                              />
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Start Time
                                <SortButton
                                  active={sortField === "startTime"}
                                  sortOrder={sortOrder}
                                  sortField={"startTime"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                End Time
                                <SortButton
                                  active={sortField === "endTime"}
                                  sortOrder={sortOrder}
                                  sortField={"endTime"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Email
                                <SortButton
                                  active={sortField === "email"}
                                  sortOrder={sortOrder}
                                  sortField={"email"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Sponsor Status
                                <SortButton
                                  active={sortField === "sponsorStatus"}
                                  sortOrder={sortOrder}
                                  sortField={"sponsorStatus"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>

                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Status
                                <SortButton
                                  active={sortField === "status"}
                                  sortOrder={sortOrder}
                                  sortField={"status"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {loading ? (
                            <tr className="animate-pulse hover:bg-slate-50 cursor-pointer text-slate-300 text-sm">
                              <td className="relative px-7 sm:w-12 sm:px-6">
                                <input
                                  type="checkbox"
                                  className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                                  checked={false}
                                />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <span className="bg-slate-300 rounded">
                                  {format(new Date(), "dd/MM/yyyy hh:mm aa")}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <span className="bg-slate-300 rounded">
                                  {format(new Date(), "dd/MM/yyyy hh:mm aa")}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <span className="bg-slate-300 rounded">
                                  email@example.com
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <span className="bg-slate-300 rounded">
                                  First Name
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <span className="bg-slate-300 rounded">
                                  Last Name
                                </span>
                              </td>

                              <td className="whitespace-nowrap px-3 py-4">
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-300 text-slate-300">
                                  Unknown
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4">
                                <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-slate-300 text-slate-300">
                                  Requested
                                </div>
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div className="bg-slate-300 rounded">
                                  Manage
                                </div>
                              </td>
                            </tr>
                          ) : (
                            visitorPassRequests.map((vpRequest) => (
                              <tr
                                key={vpRequest.id}
                                className="hover:bg-slate-50 cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    "/visitor-pass-requests/" + vpRequest.id
                                  )
                                }>
                                <td className="relative px-7 sm:w-12 sm:px-6">
                                  {selectedVpRequests.includes(vpRequest) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-amber-600" />
                                  )}
                                  <input
                                    type="checkbox"
                                    className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-amber-600 focus:ring-amber-600"
                                    value={vpRequest.email}
                                    checked={selectedVpRequests.includes(
                                      vpRequest
                                    )}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={(e) => {
                                      e.stopPropagation();
                                      setSelectedVpRequests(
                                        e.target.checked
                                          ? [...selectedVpRequests, vpRequest]
                                          : selectedVpRequests.filter(
                                              (p) => p !== vpRequest
                                            )
                                      );
                                    }}
                                  />
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {format(
                                    new Date(vpRequest.startTime),
                                    "dd/MM/yyyy hh:mm aa"
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {format(
                                    new Date(vpRequest.endTime),
                                    "dd/MM/yyyy hh:mm aa"
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {vpRequest.email}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {vpRequest.firstName}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {vpRequest.lastName}
                                </td>

                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {vpRequest.sponsorStatus === 1 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Pending
                                    </div>
                                  )}
                                  {vpRequest.sponsorStatus === 2 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Approved
                                    </div>
                                  )}
                                  {vpRequest.sponsorStatus === 3 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Declined
                                    </div>
                                  )}
                                  {vpRequest.sponsorStatus === 0 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Unknown
                                    </div>
                                  )}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {vpRequest.status === 1 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Requested
                                    </div>
                                  )}
                                  {vpRequest.status === 2 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Approved
                                    </div>
                                  )}
                                  {vpRequest.status === 3 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Rejected
                                    </div>
                                  )}
                                  {vpRequest.status === 4 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Expired
                                    </div>
                                  )}
                                  {vpRequest.status === 5 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                                      Cancelled
                                    </div>
                                  )}
                                  {vpRequest.status === 6 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                      Printed
                                    </div>
                                  )}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <div className="text-amber-600 hover:text-amber-900">
                                    Manage
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <p className="text-sm text-gray-700">
                              Showing{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber - rowsPerPage + 1}
                              </span>{" "}
                              to{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber > totalCount
                                  ? totalCount
                                  : rowsPerPage * pageNumber}
                              </span>{" "}
                              of{" "}
                              <span className="font-medium">{totalCount}</span>{" "}
                              results
                            </p>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results
                            </p>
                          )}
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination">
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* Current: "z-10 bg-amber-50 border-amber-500 text-amber-600", Default: "border-gray-300 text-gray-500 hover:bg-gray-50" */}
                            {createPageNumbers()}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={
                                rowsPerPage * pageNumber > totalCount || loading
                              }
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={sendSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully requested bulk SMS' to be sent to visitor
                      passes
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      SMS' will be queued and sent shortly
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSendSuccess(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={sendError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred while sending bulk SMS'
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please check the console, try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSendError(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={exportSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully exported visitor passes
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Your download should start automatically
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setExportSuccess(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={exportError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred while exporting visitor passes
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please check the console, try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setExportError(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      <BulkSMSModal
        open={bulkSmsModalOpen}
        setOpen={setBulkSmsModalOpen}
        setSendError={setSendError}
        setSendSuccess={setSendSuccess}
        visitorPasses={selectedVpRequests}
        status={status}
        searchField={searchField}
        searchQuery={searchQuery}
      />
      <ExportCSVModal
        open={exportCsvModalOpen}
        setOpen={setExportCsvModalOpen}
        setExportError={setExportError}
        setExportSuccess={setExportSuccess}
        visitorPasses={selectedVpRequests}
        searchField={searchField}
        searchQuery={searchQuery}
        status={status}
      />
    </>
  );
}
