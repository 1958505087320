import React, { useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import api from "../helpers/api";

interface ErrorObject {
  code: string;
  message: string;
}
interface AuthContextType {
  user: any;
  token: string;
  loading: boolean;
  signin: (username: string, password: string) => Promise<ErrorObject | void>;
  signout: (callback?: VoidFunction) => void;
  updateToken: (newToken: string) => void;
  updateUser: (newUser?: any) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [token, setToken] = React.useState<string>(
    localStorage.getItem("token") ?? ""
  );
  const [user, setUser] = React.useState<any>(
    JSON.parse(localStorage.getItem("currentUser") || "null")
  );
  const [loading, setLoading] = useState<boolean>(false);

  const signin = async (
    username: string,
    password: string
  ): Promise<void | ErrorObject> => {
    setLoading(true);
    const res = await api.login(username, password);
    if (res.data.user && res.data.token) {
      updateToken(res.data.token);
      api.updateClient(res.data.token);

      updateUser(res.data.user);
    }
    setLoading(false);
  };

  const signout = (callback?: VoidFunction) => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("currentUser");
    if (callback) {
      callback();
    }
  };

  const updateToken = (newToken: string) => {
    setToken(newToken);
    localStorage.setItem("token", newToken);
  };

  const updateUser = (newUser?: any) => {
    setLoading(true);
    if (newUser) {
      setUser(newUser);
      localStorage.setItem("currentUser", JSON.stringify(newUser));
    }
    setLoading(false);
  };

  const memoedValue = useMemo(
    () => ({
      user,
      token,
      loading,
      signin,
      signout,
      updateToken,
      updateUser,
    }),
    [token, loading]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.token) {
    return (
      <Navigate to="/signin" replace state={{ from: location.pathname }} />
    );
  }

  return children;
}
