import { Dialog, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  FolderIcon,
  GlobeIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Fragment, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DefaultProfilePic from "../../assets/img/default-profile-pic.jpg";
import G99Favicon from "../../assets/img/g99-favicon.png";
import { useAuth } from "../../utils/auth/auth";
import { classNames } from "../../utils/helpers/misc";
import { UserRole } from "../../utils/types";

export default function Sidebar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigation = [
    {
      name: "Visitor Pass Requests",
      href: "/visitor-pass-requests",
      icon: FolderIcon,
      current: location.pathname.includes("/visitor-pass-requests"),
    },
  ];

  if (auth.user && !auth.user.hasOwnProperty("roles")) {
    navigate("/signout");
  }

  if (
    auth.user &&
    auth.user.hasOwnProperty("roles") &&
    auth.user.roles.includes(UserRole.Admin)
  ) {
    if (
      navigation.filter((e) => e.name === "Airport Management").length === 0
    ) {
      navigation.push({
        name: "Airport Management",
        href: "/airport-management",
        icon: GlobeIcon,
        current: location.pathname.includes("/airport-management"),
      });
    }

    if (
      navigation.filter((e) => e.name === "Visitor Management").length === 0
    ) {
      navigation.push({
        name: "Visitor Management",
        href: "/visitor-management",
        icon: CalendarIcon,
        current: location.pathname.includes("/visitor-management"),
      });
    }

    if (navigation.filter((e) => e.name === "User Management").length === 0) {
      navigation.push({
        name: "User Management",
        href: "/user-management",
        icon: UsersIcon,
        current: location.pathname.includes("/user-management"),
      });
    }
  }

  if (
    auth.user &&
    auth.user.hasOwnProperty("roles") &&
    auth.user.roles.includes(UserRole.AirportAdmin)
  ) {
    if (navigation.filter((e) => e.name === "User Management").length === 0) {
      navigation.push({
        name: "User Management",
        href: "/user-management",
        icon: UsersIcon,
        current: location.pathname.includes("/user-management"),
      });
    }
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 py-4 overflow-y-auto">
                  <div
                    onClick={() => navigate("/user-management")}
                    className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
                  >
                    <img
                      className="h-8 w-auto"
                      src={G99Favicon}
                      alt="Workflow"
                    />
                    <span className="text-white text-xl font-bold ml-2">
                      Visitor Pass
                    </span>
                  </div>
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? "text-gray-300"
                              : "text-gray-400 group-hover:text-gray-300",
                            "mr-4 flex-shrink-0 h-6 w-6"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex bg-gray-700 p-4">
                  <div className="flex-shrink-0 group block">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src={DefaultProfilePic}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-white">
                          {auth.user.firstName && auth.user.lastName
                            ? auth.user.firstName + " " + auth.user.lastName
                            : auth.user.username}
                        </div>
                        <div
                          onClick={() => navigate("/signout")}
                          className="text-sm font-medium text-amber-400 group-hover:text-amber-500 cursor-pointer w-fit"
                        >
                          Sign out
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
          <div className="flex-1 flex flex-col py-4 overflow-y-auto">
            <div
              onClick={() => navigate("/user-management")}
              className="flex flex-row items-center flex-shrink-0 px-4 cursor-pointer opacity-80 hover:opacity-100"
            >
              <img className="h-8 w-auto" src={G99Favicon} alt="Workflow" />
              <span className="text-white text-xl font-bold ml-2">
                Visitor Pass
              </span>
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-2">
              {navigation.map((item) => (
                <Link to={item.href} key={item.name}>
                  <div
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "mr-3 flex-shrink-0 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </div>
                </Link>
              ))}
            </nav>
          </div>
          <div className="flex-shrink-0 flex bg-gray-700 p-4">
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={DefaultProfilePic}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <div className="text-sm font-medium text-white">
                    {auth.user.firstName && auth.user.lastName
                      ? auth.user.firstName + " " + auth.user.lastName
                      : auth.user.username}
                  </div>
                  <div
                    onClick={() => navigate("/signout")}
                    className="text-xs font-medium text-amber-400 group-hover:text-amber-500 cursor-pointer w-fit"
                  >
                    Sign out
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pl-64 flex flex-col flex-1">
        <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-gray-100">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  );
}
