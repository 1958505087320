import { Dialog, Listbox, Switch, Transition } from "@headlessui/react";
import {
  BanIcon,
  ChatAltIcon,
  CheckCircleIcon,
  CheckIcon,
  DocumentRemoveIcon,
  MinusIcon,
  PlusIcon,
  PrinterIcon,
  SelectorIcon,
  UserAddIcon,
  XIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import api from "../../utils/helpers/api";
import { classNames } from "../../utils/helpers/misc";
import SearchForm from "../SearchForm/SearchForm";

interface BulkSMSModalProps {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
  setSendSuccess: (newSendSuccessValue: boolean) => void;
  setSendError: (newSendErrorValue: boolean) => void;
  visitorPasses?: any[];
  status?: string;
  searchQuery?: string;
  searchField?: string;
}

export default function BulkSMSModal(props: BulkSMSModalProps) {
  const [loading, setLoading] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const visitorPassStatuses = [
    {
      name: "Active",
      id: "active",
      icon: CheckCircleIcon,
    },
    {
      name: "Requested",
      id: "1",
      icon: UserAddIcon,
    },
    {
      name: "Approved",
      id: "2",
      icon: CheckIcon,
    },
    {
      name: "Rejected",
      id: "3",
      icon: XIcon,
    },
    {
      name: "Expired",
      id: "4",
      icon: DocumentRemoveIcon,
    },
    {
      name: "Cancelled",
      id: "5",
      icon: BanIcon,
    },
    {
      name: "Printed",
      id: "6",
      icon: PrinterIcon,
    },
  ];

  const [visitorPassStatus, setVisitorPassStatus] = useState<any>(
    props.status
      ? visitorPassStatuses.filter((e) => e.id === props.status)[0]
      : visitorPassStatuses[0]
  );
  const [searchQuery, setSearchQuery] = useState<string>(
    props.searchQuery ? props.searchQuery : ""
  );
  const [searchField, setSearchField] = useState<string>(
    props.searchField ? props.searchField : ""
  );
  const [sendOnlyToCheckboxes, setSendOnlyToCheckboxes] = useState(
    props.visitorPasses && props.visitorPasses.length > 0 ? true : false
  );
  const [messageContent, setMessageContent] = useState<string>("");
  const [recipients, setRecipients] = useState<any[]>(
    props.visitorPasses && props.visitorPasses.length > 0
      ? props.visitorPasses
      : []
  );
  const [showRecipients, setShowRecipients] = useState(false);
  const cancelButtonRef = useRef(null);

  const sendBulkSms = async () => {
    setLoading(true);
    try {
      const recipientIds = recipients.map((recipient) => recipient.id);
      await api.sendBulkSmsToVisitorPasses(recipientIds, messageContent);
      props.setSendSuccess(true);
    } catch (error) {
      props.setSendError(true);
      console.error("Error occurred while sending bulk SMS - ", error);
    }
    setLoading(false);
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.open) {
      fetchVisitorPassRequests();
    }
  }, [
    searchQuery,
    searchField,
    visitorPassStatus,
    sendOnlyToCheckboxes,
    props.visitorPasses,
    props.open,
  ]);

  useEffect(() => {
    setVisitorPassStatus(
      props.status
        ? visitorPassStatuses.filter((e) => e.id === props.status)[0]
        : visitorPassStatuses[0]
    );
    setSearchQuery(props.searchQuery ? props.searchQuery : "");
    setSearchField(props.searchField ? props.searchField : "");
  }, [props.status, props.searchField, props.searchQuery]);

  const fetchVisitorPassRequests = async () => {
    setLoading(true);
    try {
      if (sendOnlyToCheckboxes) {
        setRecipients(props.visitorPasses ? props.visitorPasses : []);
      } else {
        const vpRequests = await api.getVisitorPassRequests(
          1,
          1000000,
          searchQuery,
          searchField,
          "DESC",
          "createdAt",
          visitorPassStatus.id
        );

        if (vpRequests.status === 200 && vpRequests.data.data) {
          const vpRecipients = [];
          for (const vpRequest of vpRequests.data.data) {
            if (vpRequest.mobileNumber) {
              vpRecipients.push(vpRequest);
            }
          }
          setRecipients(vpRecipients);
        }
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching visitorPassRequests to send bulk SMS to - `,
        error
      );
    }
    setLoading(false);
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  useEffect(() => {
    setInvalidForm(!messageContent || recipients.length === 0);
  }, [messageContent, recipients]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <ChatAltIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900">
                      Send Bulk SMS
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <div className="text-md font-semibold text-left">
                            Filters
                          </div>
                        </div>
                        {!sendOnlyToCheckboxes && (
                          <>
                            <div className="sm:col-span-6 max-w-sm">
                              <Listbox
                                value={visitorPassStatus}
                                onChange={setVisitorPassStatus}
                                disabled={loading || sendOnlyToCheckboxes}>
                                {({ open }) => (
                                  <>
                                    <Listbox.Label className="block text-sm text-left font-medium leading-6 text-gray-900">
                                      Status
                                    </Listbox.Label>
                                    <div className="relative mt-2">
                                      <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                        <span className="flex items-center">
                                          <visitorPassStatus.icon
                                            className={classNames(
                                              "text-gray-400 group-hover:text-gray-300 rounded-full",
                                              "flex-shrink-0 h-5 w-5"
                                            )}
                                            aria-hidden="true"
                                          />
                                          <span className="ml-3 block truncate">
                                            {visitorPassStatus.name}
                                          </span>
                                        </span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                          <SelectorIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </Listbox.Button>

                                      <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0">
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                          {visitorPassStatuses.map((status) => (
                                            <Listbox.Option
                                              key={status.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active
                                                    ? "bg-indigo-600 text-white"
                                                    : "text-gray-900",
                                                  "relative cursor-default select-none py-2 pl-3 pr-9"
                                                )
                                              }
                                              value={status}>
                                              {({ selected, active }) => (
                                                <>
                                                  <div className="flex items-center">
                                                    <status.icon
                                                      className={classNames(
                                                        "text-gray-400 group-hover:text-gray-300 rounded-full",
                                                        "flex-shrink-0 h-5 w-5"
                                                      )}
                                                      aria-hidden="true"
                                                    />
                                                    <span
                                                      className={classNames(
                                                        selected
                                                          ? "font-semibold"
                                                          : "font-normal",
                                                        "ml-3 block truncate"
                                                      )}>
                                                      {status.name}
                                                    </span>
                                                  </div>

                                                  {selected ? (
                                                    <span
                                                      className={classNames(
                                                        active
                                                          ? "text-white"
                                                          : "text-indigo-600",
                                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                                      )}>
                                                      <CheckIcon
                                                        className="h-5 w-5"
                                                        aria-hidden="true"
                                                      />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          ))}
                                        </Listbox.Options>
                                      </Transition>
                                    </div>
                                  </>
                                )}
                              </Listbox>
                            </div>
                            <div className="sm:col-span-6">
                              <SearchForm
                                searchOptions={[
                                  { name: "All", value: "all" },
                                  { name: "Email", value: "email" },
                                  { name: "Company", value: "company" },
                                  { name: "First Name", value: "firstName" },
                                  { name: "Last Name", value: "lastName" },
                                ]}
                                disabled={loading || sendOnlyToCheckboxes}
                                updateSearchQueryAndField={(
                                  newSearchQuery,
                                  newSearchField
                                ) => {
                                  setSearchQuery(newSearchQuery);
                                  setSearchField(newSearchField);
                                }}
                                initialSearchField={props.searchField}
                                initialSearchQuery={props.searchQuery}
                              />
                            </div>
                          </>
                        )}
                        {props.visitorPasses &&
                          props.visitorPasses.length > 0 && (
                            <div className="sm:col-span-6">
                              <Switch.Group
                                as="div"
                                className="flex items-center">
                                <Switch
                                  checked={sendOnlyToCheckboxes}
                                  onChange={setSendOnlyToCheckboxes}
                                  className={classNames(
                                    sendOnlyToCheckboxes
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                  )}>
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      sendOnlyToCheckboxes
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                                <Switch.Label
                                  as="span"
                                  className="ml-3 text-sm">
                                  <span className="font-medium text-gray-900">
                                    Send only to passes with checkboxes ticked
                                  </span>{" "}
                                  <span className="text-gray-500">
                                    (
                                    {props.visitorPasses &&
                                    props.visitorPasses.length > 0
                                      ? props.visitorPasses.length
                                      : 0}{" "}
                                    passes)
                                  </span>
                                </Switch.Label>
                              </Switch.Group>
                            </div>
                          )}

                        <div className="sm:col-span-6">
                          <div className="text-md font-semibold text-left">
                            Message Content{showAsteriskIfEmpty(messageContent)}
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <div>
                            <div className="mt-2">
                              <textarea
                                rows={2}
                                name="comment"
                                id="comment"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                value={messageContent}
                                onChange={(e) =>
                                  setMessageContent(e.target.value)
                                }
                                placeholder="Message to send to selected passes"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="sm:col-span-6 mt-2">
                          <div className="text-md font-semibold text-left">
                            Message Recipients
                          </div>
                        </div>
                        <div className="sm:col-span-6 mt-2 text-sm text-left">
                          This message will be sent to{" "}
                          <button
                            disabled={loading || recipients.length === 0}
                            onClick={() => setShowRecipients(!showRecipients)}
                            className="inline-flex flex-row items-center font-semibold opacity-80 cursor-pointer hover:opacity-100 text-indigo-500 disabled:cursor-default disabled:opacity-50">
                            {recipients.length}{" "}
                            {recipients.length === 1 ? "pass" : "passes"}
                            {recipients.length > 0 && showRecipients ? (
                              <MinusIcon className="ml-1 h-4 w-4" />
                            ) : (
                              <PlusIcon className="ml-1 h-4 w-4" />
                            )}
                          </button>
                        </div>
                        {showRecipients && recipients.length > 0 && (
                          <div className="sm:col-span-6 mt-2 text-sm text-left">
                            {recipients.map((recipient) => (
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-5 w-5">
                                  <CheckIcon
                                    className="h-5 w-5 text-indigo-500"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-3 text-sm font-medium text-gray-900">
                                  {recipient.firstName} {recipient.lastName}
                                  {" • "}
                                  <span className="text-gray-500">
                                    {format(
                                      new Date(recipient.startTime),
                                      "yy/MM/dd HH:mm"
                                    )}
                                  </span>
                                  {" • "}
                                  <span className="text-gray-500">
                                    {recipient.mobileNumber}
                                  </span>
                                  {" • "}
                                  {recipient.status === 1 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                      Requested
                                    </div>
                                  )}
                                  {recipient.status === 2 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                      Approved
                                    </div>
                                  )}
                                  {recipient.status === 3 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                      Rejected
                                    </div>
                                  )}
                                  {recipient.status === 4 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                      Expired
                                    </div>
                                  )}
                                  {recipient.status === 5 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-red-200 text-red-900">
                                      Cancelled
                                    </div>
                                  )}
                                  {recipient.status === 6 && (
                                    <div className="inline-flex items-center px-2.5 py-0.5 my-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                      Printed
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    disabled={loading || invalidForm}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50"
                    onClick={() => sendBulkSms()}>
                    Send
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
                {invalidForm && (
                  <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 sm:px-0 px-4 mt-2">
                    <div className="my-1">
                      Please complete all required information (*) and ensure at
                      least one recipient is selected to continue
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
