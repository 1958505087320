import { Dialog, Listbox, Switch, Transition } from "@headlessui/react";
import {
  BanIcon,
  CheckCircleIcon,
  CheckIcon,
  DocumentRemoveIcon,
  IdentificationIcon,
  MinusIcon,
  PlusIcon,
  PrinterIcon,
  SelectorIcon,
  TableIcon,
  UserAddIcon,
  XIcon,
} from "@heroicons/react/outline";
import { format } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import { classNames } from "../../utils/helpers/misc";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import api from "../../utils/helpers/api";
import SearchForm from "../SearchForm/SearchForm";

interface ExportCSVModalProps {
  open: boolean;
  setOpen: (newOpenValue: boolean) => void;
  setExportSuccess: (newExportSuccessValue: boolean) => void;
  setExportError: (newExportErrorValue: boolean) => void;
  visitorPasses?: any[];
  searchQuery?: string;
  searchField?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
}

export default function ExportCSVModal(props: ExportCSVModalProps) {
  const [loading, setLoading] = useState(false);
  const [invalidForm, setInvalidForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>(
    props.searchQuery ? props.searchQuery : ""
  );
  const [searchField, setSearchField] = useState<string>(
    props.searchField ? props.searchField : ""
  );
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [sendOnlyToCheckboxes, setExportOnlyToCheckboxes] = useState(
    props.visitorPasses && props.visitorPasses.length > 0 ? true : false
  );
  const [passesToExport, setVisitorPassesToExport] = useState<any[]>(
    props.visitorPasses && props.visitorPasses.length > 0
      ? props.visitorPasses
      : []
  );
  const [showVisitorPassesToExport, setShowVisitorPassesToExport] =
    useState(false);
  const cancelButtonRef = useRef(null);
  const visitorPassStatuses = [
    {
      name: "All",
      id: "",
      icon: IdentificationIcon,
    },
    {
      name: "Active",
      id: "active",
      icon: CheckCircleIcon,
    },
    {
      name: "Requested",
      id: "1",
      icon: UserAddIcon,
    },
    {
      name: "Approved",
      id: "2",
      icon: CheckIcon,
    },
    {
      name: "Rejected",
      id: "3",
      icon: XIcon,
    },
    {
      name: "Expired",
      id: "4",
      icon: DocumentRemoveIcon,
    },
    {
      name: "Cancelled",
      id: "5",
      icon: BanIcon,
    },
    {
      name: "Printed",
      id: "6",
      icon: PrinterIcon,
    },
  ];
  const [visitorPassStatus, setVisitorPassStatus] = useState<any>(
    props.status
      ? visitorPassStatuses.filter((e) => e.id === props.status)[0]
      : visitorPassStatuses[0]
  );

  const exportToCsv = async () => {
    setLoading(true);
    try {
      const visitorPassIds = passesToExport.map((pass) => pass.id);
      const res = await api.exportVisitorPassesToCsv(visitorPassIds);

      // Download response as a csv file
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `visitor-passes-${format(new Date(), "yyyy-MM-dd")}.csv`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();

      props.setExportSuccess(true);
    } catch (error) {
      props.setExportError(true);
      console.error(
        "Error occurred while exporting visitor passes to CSV - ",
        error
      );
    }
    setLoading(false);
    props.setOpen(false);
  };

  useEffect(() => {
    if (props.open) {
      fetchVisitorPasses();
    }
  }, [
    searchQuery,
    searchField,
    sendOnlyToCheckboxes,
    visitorPassStatus,
    props.visitorPasses,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    setSearchQuery(props.searchQuery ? props.searchQuery : "");
    setSearchField(props.searchField ? props.searchField : "");
    setStartDate(props.startDate ? props.startDate : "");
    setEndDate(props.endDate ? props.endDate : "");
    setVisitorPassStatus(
      props.status
        ? visitorPassStatuses.filter((e) => e.id === props.status)[0]
        : visitorPassStatuses[0]
    );
  }, [
    props.searchField,
    props.searchQuery,
    props.startDate,
    props.endDate,
    props.status,
  ]);

  const fetchVisitorPasses = async () => {
    setLoading(true);
    try {
      if (sendOnlyToCheckboxes) {
        setVisitorPassesToExport(
          props.visitorPasses ? props.visitorPasses : []
        );
      } else {
        const visitorPasses = await api.getVisitorPassRequests(
          1,
          1000000,
          searchQuery,
          searchField,
          "DESC",
          "createdAt",
          visitorPassStatus.id,
          startDate,
          endDate
        );

        if (visitorPasses.status === 200 && visitorPasses.data.data) {
          setVisitorPassesToExport(visitorPasses.data.data);
        }
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching visitorPasses to export to CSV - `,
        error
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    setInvalidForm(passesToExport.length === 0);
  }, [passesToExport]);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-y-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                    <TableIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900">
                      Export to CSV
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="grid grid-cols-1 gap-y-2 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <div className="text-md font-semibold text-left">
                            Filters
                          </div>
                        </div>
                        {!sendOnlyToCheckboxes && (
                          <>
                            <div className="sm:col-span-6 flex flex-col">
                              <div className="mb-4 max-w-sm">
                                <Listbox
                                  value={visitorPassStatus}
                                  onChange={setVisitorPassStatus}
                                  disabled={loading || sendOnlyToCheckboxes}>
                                  {({ open }) => (
                                    <>
                                      <Listbox.Label className="block text-sm text-left font-medium leading-6 text-gray-900">
                                        Status
                                      </Listbox.Label>
                                      <div className="relative mt-2">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                                          <span className="flex items-center">
                                            <visitorPassStatus.icon
                                              className={classNames(
                                                "text-gray-400 group-hover:text-gray-300 rounded-full",
                                                "flex-shrink-0 h-5 w-5"
                                              )}
                                              aria-hidden="true"
                                            />
                                            <span className="ml-3 block truncate">
                                              {visitorPassStatus.name}
                                            </span>
                                          </span>
                                          <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                            <SelectorIcon
                                              className="h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                            />
                                          </span>
                                        </Listbox.Button>

                                        <Transition
                                          show={open}
                                          as={Fragment}
                                          leave="transition ease-in duration-100"
                                          leaveFrom="opacity-100"
                                          leaveTo="opacity-0">
                                          <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {visitorPassStatuses.map(
                                              (status) => (
                                                <Listbox.Option
                                                  key={status.id}
                                                  className={({ active }) =>
                                                    classNames(
                                                      active
                                                        ? "bg-indigo-600 text-white"
                                                        : "text-gray-900",
                                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                                    )
                                                  }
                                                  value={status}>
                                                  {({ selected, active }) => (
                                                    <>
                                                      <div className="flex items-center">
                                                        <status.icon
                                                          className={classNames(
                                                            "text-gray-400 group-hover:text-gray-300 rounded-full",
                                                            "flex-shrink-0 h-5 w-5"
                                                          )}
                                                          aria-hidden="true"
                                                        />
                                                        <span
                                                          className={classNames(
                                                            selected
                                                              ? "font-semibold"
                                                              : "font-normal",
                                                            "ml-3 block truncate"
                                                          )}>
                                                          {status.name}
                                                        </span>
                                                      </div>

                                                      {selected ? (
                                                        <span
                                                          className={classNames(
                                                            active
                                                              ? "text-white"
                                                              : "text-indigo-600",
                                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                                          )}>
                                                          <CheckIcon
                                                            className="h-5 w-5"
                                                            aria-hidden="true"
                                                          />
                                                        </span>
                                                      ) : null}
                                                    </>
                                                  )}
                                                </Listbox.Option>
                                              )
                                            )}
                                          </Listbox.Options>
                                        </Transition>
                                      </div>
                                    </>
                                  )}
                                </Listbox>
                              </div>
                              <DateRangePicker
                                updateDates={(newStartDate, newEndDate) => {
                                  setStartDate(newStartDate);
                                  setEndDate(newEndDate);
                                }}
                                disabled={loading}
                              />
                              <SearchForm
                                searchOptions={[
                                  { name: "All", value: "all" },
                                  { name: "Email", value: "email" },
                                  { name: "Company", value: "company" },
                                  { name: "First Name", value: "firstName" },
                                  { name: "Last Name", value: "lastName" },
                                ]}
                                disabled={loading || sendOnlyToCheckboxes}
                                updateSearchQueryAndField={(
                                  newSearchQuery,
                                  newSearchField
                                ) => {
                                  setSearchQuery(newSearchQuery);
                                  setSearchField(newSearchField);
                                }}
                              />
                            </div>
                          </>
                        )}
                        {props.visitorPasses &&
                          props.visitorPasses.length > 0 && (
                            <div className="sm:col-span-6">
                              <Switch.Group
                                as="div"
                                className="flex items-center">
                                <Switch
                                  checked={sendOnlyToCheckboxes}
                                  onChange={setExportOnlyToCheckboxes}
                                  className={classNames(
                                    sendOnlyToCheckboxes
                                      ? "bg-indigo-600"
                                      : "bg-gray-200",
                                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                                  )}>
                                  <span
                                    aria-hidden="true"
                                    className={classNames(
                                      sendOnlyToCheckboxes
                                        ? "translate-x-5"
                                        : "translate-x-0",
                                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                                    )}
                                  />
                                </Switch>
                                <Switch.Label
                                  as="span"
                                  className="ml-3 text-sm">
                                  <span className="font-medium text-gray-900">
                                    Export only passes with checkboxes ticked
                                  </span>{" "}
                                  <span className="text-gray-500">
                                    (
                                    {props.visitorPasses &&
                                    props.visitorPasses.length > 0
                                      ? props.visitorPasses.length
                                      : 0}{" "}
                                    passes)
                                  </span>
                                </Switch.Label>
                              </Switch.Group>
                            </div>
                          )}

                        <div className="sm:col-span-6 mt-2">
                          <div className="text-md font-semibold text-left">
                            Exported Visitor Passes
                          </div>
                        </div>
                        <div className="sm:col-span-6 mt-2 text-sm text-left">
                          The exported CSV will contain{" "}
                          <button
                            disabled={loading || passesToExport.length === 0}
                            onClick={() =>
                              setShowVisitorPassesToExport(
                                !showVisitorPassesToExport
                              )
                            }
                            className="inline-flex flex-row items-center font-semibold opacity-80 cursor-pointer hover:opacity-100 text-indigo-500 disabled:cursor-default disabled:opacity-50">
                            {passesToExport.length}{" "}
                            {passesToExport.length === 1 ? "pass" : "passes"}
                            {passesToExport.length > 0 &&
                            showVisitorPassesToExport ? (
                              <MinusIcon className="ml-1 h-4 w-4" />
                            ) : (
                              <PlusIcon className="ml-1 h-4 w-4" />
                            )}
                          </button>
                        </div>
                        {showVisitorPassesToExport &&
                          passesToExport.length > 0 && (
                            <div className="sm:col-span-6 mt-2 text-sm text-left">
                              {passesToExport.map((pass) => (
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-5 w-5">
                                    <CheckIcon
                                      className="h-5 w-5 text-indigo-500"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div className="ml-3 text-sm font-medium text-gray-900">
                                    {pass.firstName} {pass.lastName}
                                    {" • "}
                                    <span className="text-gray-500">
                                      {format(
                                        new Date(pass.startTime),
                                        "dd/MM/yyyy HH:mm"
                                      )}
                                    </span>
                                    {" - "}
                                    <span className="text-gray-500">
                                      {format(
                                        new Date(pass.endTime),
                                        "dd/MM/yyyy HH:mm"
                                      )}
                                    </span>
                                    {" • "}
                                    {pass.status === 1 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                                        Requested
                                      </div>
                                    )}
                                    {pass.status === 2 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                        Approved
                                      </div>
                                    )}
                                    {pass.status === 3 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                        Rejected
                                      </div>
                                    )}
                                    {pass.status === 4 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                        Expired
                                      </div>
                                    )}
                                    {pass.status === 5 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-red-200 text-red-900">
                                        Cancelled
                                      </div>
                                    )}
                                    {pass.status === 6 && (
                                      <div className="inline-flex items-center px-2.5 py-0.5 my-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                        Printed
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    disabled={loading || invalidForm}
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:opacity-50"
                    onClick={() => exportToCsv()}>
                    Export
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => props.setOpen(false)}
                    ref={cancelButtonRef}>
                    Cancel
                  </button>
                </div>
                {invalidForm && (
                  <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 sm:px-0 px-4 mt-2">
                    <div className="my-1">
                      Please complete all required information (*) and ensure at
                      least one visitor pass is selected to continue
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
