import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { ID } from "../../../utils/types";

interface IDDetailsProps {
  ids: ID[];
  setIds: (newIds: ID[]) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}
export const IDDetails = (props: IDDetailsProps) => {
  useEffect(() => {
    // Set invalid form if any of the fields are empty
    if (props.ids.some((id) => !id.idType || !id.idNumber)) {
      props.setInvalidForm(true);
    } else {
      props.setInvalidForm(false);
    }
  }, [props]);

  const addNewId = () => {
    props.setIds([...props.ids, { idType: "Unknown", idNumber: "" }]);
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  return (
    <div className="max-w-screen-sm px-4 mt-4 sm:px-0">
      <div className="grid grid-cols-9 gap-6">
        {props.ids.map((id, index) => (
          <>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="id-type"
                className="block text-sm font-medium text-gray-700"
              >
                ID Type{showAsteriskIfEmpty(id.idType)}
              </label>
              <select
                id="id-type"
                name="id-type"
                autoComplete="id-type"
                onChange={(e) => {
                  const newIds = [...props.ids];
                  newIds[index].idType = e.target.value as
                    | "Unknown"
                    | "Passport"
                    | "Driver's License";
                  props.setIds(newIds);
                }}
                value={id.idType}
                className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
              >
                <option>Driver's License</option>
                <option>Passport</option>
              </select>
            </div>
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="id-number"
                className="block text-sm font-medium text-gray-700"
              >
                ID Number{showAsteriskIfEmpty(id.idNumber)}
              </label>
              <div className="mt-1">
                <input
                  id="id-number"
                  name="id-number"
                  type="text"
                  autoComplete="new-id-number"
                  onChange={(e) => {
                    const newIds = [...props.ids];
                    newIds[index].idNumber = e.target.value;
                    props.setIds(newIds);
                  }}
                  required
                  value={id.idNumber}
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="self-end col-span-6 sm:col-span-3">
              <button
                onClick={() => {
                  const newIds = [...props.ids];
                  newIds.splice(index, 1);
                  props.setIds(newIds);
                }}
                className="flex items-center justify-center px-4 py-2 mt-auto text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
              >
                <TrashIcon className="w-5 h-5 mr-1.5" />
                Remove ID
              </button>
            </div>
          </>
        ))}

        <div className="col-span-6 sm:col-span-3">
          <button
            onClick={() => addNewId()}
            disabled={props.ids.length >= 2}
            className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
          >
            <PlusIcon className="w-5 h-5 mr-1.5" />
            Add ID
          </button>
        </div>
      </div>
    </div>
  );
};
