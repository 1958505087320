import { useEffect, useState } from "react";

interface SponsorDetailsProps {
  sponsorName: string;
  setSponsorName: (newSponsorName: string) => void;
  sponsorAsicId: string;
  setSponsorAsicId: (newSponsorAsicId: string) => void;
  sponsorAsicExpiryMonth: string;
  setSponsorAsicExpiryMonth: (newSponsorAsicExpiryMonth: string) => void;
  sponsorAsicExpiryYear: string;
  setSponsorAsicExpiryYear: (newSponsorAsicExpiryYear: string) => void;
  sponsorCompany: string;
  setSponsorCompany: (newSponsorCompany: string) => void;
  sponsorEmail: string;
  setSponsorEmail: (newSponsorEmail: string) => void;
  sponsorMobileNumber: string;
  setSponsorMobileNumber: (newSponsorMobileNumber: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const SponsorDetails = (props: SponsorDetailsProps) => {
  const [sponsorAsicExpired, setSponsorAsicExpired] = useState<boolean>(false);

  useEffect(() => {
    props.setInvalidForm(
      !props.sponsorName ||
        !props.sponsorAsicId ||
        !props.sponsorAsicExpiryMonth ||
        !props.sponsorAsicExpiryYear ||
        !props.sponsorCompany ||
        (!props.sponsorEmail && !props.sponsorMobileNumber)
    );

    const today = new Date();
    const expiryDate = new Date(
      parseInt(props.sponsorAsicExpiryYear),
      parseInt(props.sponsorAsicExpiryMonth) - 1,
      1
    );
    if (expiryDate < today) {
      setSponsorAsicExpired(true);
      props.setInvalidForm(true);
    } else {
      setSponsorAsicExpired(false);
    }
  }, [
    props.sponsorName,
    props.sponsorAsicId,
    props.sponsorAsicExpiryMonth,
    props.sponsorAsicExpiryYear,
    props.sponsorCompany,
    props.sponsorEmail,
    props.sponsorMobileNumber,
  ]);

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  return (
    <div className="mt-4 sm:px-0 px-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-name"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor Name{showAsteriskIfEmpty(props.sponsorName)}
          </label>
          <div className="mt-1">
            <input
              id="sponsor-name"
              name="sponsor-name"
              type="text"
              autoComplete="new-sponsor-name"
              onChange={(e) => props.setSponsorName(e.target.value)}
              required
              value={props.sponsorName}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-asic-id"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor ASIC ID{showAsteriskIfEmpty(props.sponsorAsicId)}
          </label>
          <div className="mt-1">
            <input
              id="sponsor-asic-id"
              name="sponsor-asic-id"
              type="text"
              autoComplete="new-sponsor-asic-id"
              onChange={(e) =>
                props.setSponsorAsicId(e.target.value.toUpperCase())
              }
              required
              value={props.sponsorAsicId}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-asic-expiry-month"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor ASIC Expiry Month
            {showAsteriskIfEmpty(props.sponsorAsicExpiryMonth)}
          </label>
          <div className="mt-1">
            <select
              id="sponsor-asic-expiry-month"
              name="sponsor-asic-expiry-month"
              autoComplete="new-sponsor-asic-expiry-month"
              value={props.sponsorAsicExpiryMonth}
              onChange={(e) => props.setSponsorAsicExpiryMonth(e.target.value)}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
            >
              <option value={1}>Jan</option>
              <option value={2}>Feb</option>
              <option value={3}>Mar</option>
              <option value={4}>Apr</option>
              <option value={5}>May</option>
              <option value={6}>Jun</option>
              <option value={7}>Jul</option>
              <option value={8}>Aug</option>
              <option value={9}>Sep</option>
              <option value={10}>Oct</option>
              <option value={11}>Nov</option>
              <option value={12}>Dec</option>
            </select>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-asic-expiry-year"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor ASIC Expiry Year
            {showAsteriskIfEmpty(props.sponsorAsicExpiryYear)}
          </label>
          <div className="mt-1">
            <select
              id="sponsor-asic-expiry-year"
              name="sponsor-asic-expiry-year"
              autoComplete="new-sponsor-asic-expiry-year"
              value={props.sponsorAsicExpiryYear}
              onChange={(e) => props.setSponsorAsicExpiryYear(e.target.value)}
              required
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
            >
              <option>{new Date().getFullYear()}</option>
              <option>{new Date().getFullYear() + 1}</option>
              <option>{new Date().getFullYear() + 2}</option>
            </select>
          </div>
        </div>
        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-company"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor Company{showAsteriskIfEmpty(props.sponsorCompany)}
          </label>
          <div className="mt-1">
            <input
              id="sponsor-company"
              name="sponsor-company"
              type="text"
              autoComplete="new-sponsor-company"
              onChange={(e) => props.setSponsorCompany(e.target.value)}
              required
              value={props.sponsorCompany}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-email"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor Email
            {showAsteriskIfEmpty(
              props.sponsorMobileNumber || props.sponsorEmail ? "non-empty" : ""
            )}
          </label>
          <div className="mt-1">
            <input
              id="sponsor-email"
              name="sponsor-email"
              type="email"
              autoComplete="new-sponsor-email"
              onChange={(e) => props.setSponsorEmail(e.target.value)}
              required
              value={props.sponsorEmail}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="col-span-6 sm:col-span-3">
          <label
            htmlFor="sponsor-mobile"
            className="block text-sm font-medium text-gray-700"
          >
            Sponsor Mobile Number
            {showAsteriskIfEmpty(
              props.sponsorMobileNumber || props.sponsorEmail ? "non-empty" : ""
            )}
          </label>
          <div className="mt-1">
            <input
              id="sponsor-mobile"
              name="sponsor-mobile"
              type="text"
              autoComplete="new-sponsor-mobile"
              onChange={(e) => props.setSponsorMobileNumber(e.target.value)}
              required
              value={props.sponsorMobileNumber}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
            />
          </div>
        </div>
      </div>
      {sponsorAsicExpired && (
        <div className="col-span-12 flex flex-row text-sm font-medium text-red-500 mt-3">
          <div className="my-1">
            Please choose an ASIC expiry month and year that is not currently
            expired.
          </div>
        </div>
      )}
    </div>
  );
};
