import { Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  CreditCardIcon,
  ExclamationIcon,
  MailIcon,
  MinusIcon,
  OfficeBuildingIcon,
  PhoneIcon,
  PlusIcon,
  StatusOnlineIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchForm from "../../../components/SearchForm/SearchForm";
import SortButton from "../../../components/SortButton/SortButton";
import { useAuth } from "../../../utils/auth/auth";
import api from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";
interface SelectVisitorProps {
  visitorId: string;
  setVisitorId: (newVisitorId: string) => void;
  firstName: string;
  setFirstName: (newFirstName: string) => void;
  lastName: string;
  setLastName: (newLastName: string) => void;
  email: string;
  setEmail: (newEmail: string) => void;
  mobileNumber: string;
  setMobileNumber: (newMobileNumber: string) => void;
  company: string;
  setCompany: (newCompany: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
  setFlaggedBlockedVisitor: (newFlaggedBlockedVisitor: boolean) => void;
}
export const SelectVisitor = (props: SelectVisitorProps) => {
  const { isInitiated } = usePageInitialization();
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertExpanded, setAlertExpanded] = useState<boolean>(false);
  const [visitorBlocked, setVisitorBlocked] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [visitors, setVisitors] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  useAuthToken();

  useEffect(() => {
    if (!isInitiated) return;
    fetchVisitors();
  }, [
    pageNumber,
    searchQuery,
    searchField,
    sortOrder,
    sortField,
    status,
    isInitiated,
  ]);

  const fetchVisitors = async () => {
    setLoading(true);
    try {
      const visitors = await api.getVisitors(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status
      );
      if (visitors.status === 200 && visitors.data.data) {
        setTotalCount(visitors.data.totalCount);
        setVisitors(visitors.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} visitors of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  useEffect(() => {
    if (!props.visitorId) {
      if (
        !props.firstName ||
        !props.lastName ||
        !props.email ||
        !props.mobileNumber ||
        !props.company
      ) {
        props.setInvalidForm(true);
      }
    } else {
      props.setInvalidForm(false);
    }

    if (alertMessage) {
      props.setInvalidForm(true);
    }
  }, [
    props.firstName,
    props.lastName,
    props.email,
    props.mobileNumber,
    props.company,
    props.visitorId,
    alertMessage,
  ]);

  useEffect(() => {
    if (searchQuery && searchField) {
      setLoading(true);
      props.setVisitorId("");
      api
        .getVisitors(1, 10, searchQuery, searchField, "DESC", "", "")
        .then((visitorsRes) => {
          setSearchResults(visitorsRes.data.data);
        })
        .catch((error) => {
          setError(true);
          console.error("Error while searching for visitors - ", error);
        })
        .finally(() => setLoading(false));
    }
  }, [searchQuery, searchField]);

  const selectVisitor = (visitor: any) => {
    if (visitor.id === props.visitorId) {
      props.setVisitorId("");
      setAlertMessage("");
      props.setFlaggedBlockedVisitor(true);
      setVisitorBlocked(false);
    } else if (visitor) {
      props.setVisitorId(visitor.id);
      if (visitor.status === 2) {
        // Visitor is flagged so need to display an alert message
        setAlertMessage(visitor.alertMessage);
        props.setFlaggedBlockedVisitor(true);
        setVisitorBlocked(false);
      } else if (visitor.status === 3) {
        // Visitor is blocked
        props.setFlaggedBlockedVisitor(true);
        setVisitorBlocked(true);
        setAlertMessage("");
      } else {
        props.setFlaggedBlockedVisitor(false);
        setVisitorBlocked(false);
        setAlertMessage("");
      }
    }
  };

  useEffect(() => {
    if (!props.visitorId) {
      setAlertMessage("");
      props.setFirstName("");
      props.setLastName("");
      props.setEmail("");
      props.setMobileNumber("");
      props.setCompany("");
    } else {
      setLoading(true);
      api
        .getVisitorById(props.visitorId)
        .then((visitorRes) => {
          if (visitorRes.status === 200 && visitorRes.data) {
            props.setFirstName(visitorRes.data.firstName);
            props.setLastName(visitorRes.data.lastName);
            props.setEmail(visitorRes.data.email);
            props.setMobileNumber(visitorRes.data.phone);
            props.setCompany(visitorRes.data.company);
            props.setVisitorId(visitorRes.data.id);
            if (visitorRes.data.status === 2) {
              // Visitor is flagged so need to display an alert message
              setAlertMessage(visitorRes.data.alertMessage);
              props.setFlaggedBlockedVisitor(true);
              setVisitorBlocked(false);
            } else if (visitorRes.data.status === 3) {
              // Visitor is blocked
              props.setFlaggedBlockedVisitor(true);
              setVisitorBlocked(true);
              setAlertMessage("");
            } else {
              props.setFlaggedBlockedVisitor(false);
              setVisitorBlocked(false);
              setAlertMessage("");
            }
          }
        })
        .finally(() => setLoading(false));
    }
  }, [props.visitorId]);

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  return (
    <>
      <div className="max-w-screen-lg px-4 sm:px-0">
        {props.visitorId && !loading && (
          <>
            <div className="mb-2 font-medium">Selected Visitor</div>
            <div className="lg:col-start-3 lg:row-end-1">
              <div className="rounded-lg shadow-sm bg-gray-50 ring-2 ring-amber-500">
                <dl className="flex flex-wrap py-6">
                  <div className="flex flex-none w-full px-6 gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Client</span>
                      <UserCircleIcon
                        className="w-5 h-6 text-amber-500"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm font-medium leading-6 text-gray-900">
                      {props.firstName} {props.lastName}
                    </dd>
                  </div>
                  <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Mobile number</span>
                      <PhoneIcon
                        className="w-5 h-6 text-amber-500"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      {props.mobileNumber}
                    </dd>
                  </div>
                  <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Email address</span>
                      <MailIcon
                        className="w-5 h-6 text-amber-500"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      {props.email}
                    </dd>
                  </div>
                  <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Company</span>
                      <OfficeBuildingIcon
                        className="w-5 h-6 text-amber-500"
                        aria-hidden="true"
                      />
                    </dt>
                    <dd className="text-sm leading-6 text-gray-500">
                      {props.company}
                    </dd>
                  </div>
                  <div className="flex flex-none w-full px-6 mt-4 gap-x-4">
                    <button
                      onClick={() => {
                        props.setFirstName("");
                        props.setLastName("");
                        props.setEmail("");
                        props.setMobileNumber("");
                        props.setCompany("");
                        props.setVisitorId("");
                      }}
                      className="flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50">
                      <MinusIcon className="w-5 h-5 mr-2" aria-hidden="true" />
                      Deselect
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          </>
        )}

        {!props.visitorId && (
          <>
            <div className="mt-4 mb-2 font-medium">Create Visitor</div>
            <div className="grid max-w-screen-sm grid-cols-6 gap-6">
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block text-sm font-medium text-gray-700">
                  First Name{showAsteriskIfEmpty(props.firstName)}
                </label>
                <div className="mt-1">
                  <input
                    id="first-name"
                    name="first-name"
                    type="text"
                    autoComplete="new-first-name"
                    onChange={(e) => props.setFirstName(e.target.value)}
                    required
                    value={props.firstName}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700">
                  Last Name{showAsteriskIfEmpty(props.lastName)}
                </label>
                <div className="mt-1">
                  <input
                    id="last-name"
                    name="last-name"
                    type="text"
                    autoComplete="new-last-name"
                    onChange={(e) => props.setLastName(e.target.value)}
                    required
                    value={props.lastName}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700">
                  Email address{showAsteriskIfEmpty(props.email)}
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="new-email"
                    onChange={(e) => props.setEmail(e.target.value)}
                    required
                    value={props.email}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="mobile-number"
                  className="block text-sm font-medium text-gray-700">
                  Mobile Number{showAsteriskIfEmpty(props.mobileNumber)}
                </label>
                <div className="mt-1">
                  <input
                    id="mobile-number"
                    name="mobile-number"
                    type="text"
                    autoComplete="new-mobile-number"
                    onChange={(e) => props.setMobileNumber(e.target.value)}
                    required
                    value={props.mobileNumber}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700">
                  Company{showAsteriskIfEmpty(props.company)}
                </label>
                <div className="mt-1">
                  <input
                    id="company"
                    name="company"
                    type="text"
                    autoComplete="new-company"
                    onChange={(e) => props.setCompany(e.target.value)}
                    required
                    value={props.company}
                    className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className="my-2 font-medium">
          <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-800">
            OR
          </span>
        </div>
        <div className="mb-2 font-medium">Search for Visitor</div>
        <SearchForm
          searchOptions={[
            { name: "All", value: "all" },
            { name: "Email", value: "email" },
            { name: "First Name", value: "firstName" },
            { name: "Last Name", value: "lastName" },
            { name: "Phone Number", value: "phone" },
          ]}
          updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
            setSearchQuery(newSearchQuery);
            setSearchField(newSearchField);
          }}
        />
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-100">
                  <tr>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        Created At
                        <SortButton
                          active={sortField === "createdAt"}
                          sortOrder={sortOrder}
                          sortField={"createdAt"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        VP Number
                        <SortButton
                          active={sortField === "vpNumber"}
                          sortOrder={sortOrder}
                          sortField={"vpNumber"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        Email
                        <SortButton
                          active={sortField === "email"}
                          sortOrder={sortOrder}
                          sortField={"email"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        First Name
                        <SortButton
                          active={sortField === "firstName"}
                          sortOrder={sortOrder}
                          sortField={"firstName"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        Last Name
                        <SortButton
                          active={sortField === "lastName"}
                          sortOrder={sortOrder}
                          sortField={"lastName"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        Phone Number
                        <SortButton
                          active={sortField === "phone"}
                          sortOrder={sortOrder}
                          sortField={"phone"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="inline-flex items-center">
                        Status
                        <SortButton
                          active={sortField === "status"}
                          sortOrder={sortOrder}
                          sortField={"status"}
                          updateSortCriteria={updateSortCriteria}
                        />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y">
                  {visitors.map((visitor) => (
                    <tr
                      key={visitor.id}
                      className={classNames(
                        "cursor-pointer",
                        props.visitorId === visitor.id
                          ? "bg-amber-50 border-x-4 border-amber-500"
                          : "hover:bg-slate-50"
                      )}
                      style={{
                        transition: "all .15s ease",
                        borderTop:
                          props.visitorId === visitor.id
                            ? "2px solid #edf2f7"
                            : "",
                      }}
                      onClick={() => selectVisitor(visitor)}>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {new Date(visitor.createdAt).toDateString()}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.vpNumber ?? "N/A"}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.email}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.firstName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.lastName}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.phone}
                      </td>
                      <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                        {visitor.status === 1 && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </div>
                        )}
                        {visitor.status === 2 && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                            Flagged
                          </div>
                        )}
                        {visitor.status === 3 && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Blocked
                          </div>
                        )}
                        {visitor.status === 4 && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                            Duplicate
                          </div>
                        )}
                        {visitor.status === 0 && (
                          <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-900">
                            Unknown
                          </div>
                        )}
                      </td>
                      <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                        <div
                          onClick={() => selectVisitor(visitor)}
                          className="text-amber-600 hover:text-amber-900">
                          {props.visitorId === visitor.id ? (
                            <CheckCircleIcon className="w-5 h-5 mx-auto" />
                          ) : (
                            "Select"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex items-center justify-between py-3 border-t border-gray-200">
              <div className="flex justify-between flex-1 sm:hidden">
                <a
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50">
                  Previous
                </a>
                <a
                  href="#"
                  className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50">
                  Next
                </a>
              </div>
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  {loading ? (
                    <p className="text-sm text-gray-700">Loading...</p>
                  ) : totalCount > 0 ? (
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">
                        {rowsPerPage * pageNumber - rowsPerPage + 1}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium">
                        {rowsPerPage * pageNumber > totalCount
                          ? totalCount
                          : rowsPerPage * pageNumber}
                      </span>{" "}
                      of <span className="font-medium">{totalCount}</span>{" "}
                      results
                    </p>
                  ) : (
                    <p className="text-sm text-gray-700">Showing 0 results</p>
                  )}
                </div>
                <div>
                  <nav
                    className="relative z-0 inline-flex -space-x-px bg-white rounded-md shadow-sm"
                    aria-label="Pagination">
                    <button
                      onClick={() => setPageNumber(pageNumber - 1)}
                      disabled={pageNumber === 1 || loading}
                      className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                      <span className="sr-only">Previous</span>
                      <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                    {/* Current: "z-10 bg-amber-50 border-amber-500 text-amber-600", Default: "border-gray-300 text-gray-500 hover:bg-gray-50" */}
                    {createPageNumbers()}
                    <button
                      onClick={() => setPageNumber(pageNumber + 1)}
                      disabled={
                        rowsPerPage * pageNumber > totalCount || loading
                      }
                      className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                      <span className="sr-only">Next</span>
                      <ChevronRightIcon
                        className="w-5 h-5"
                        aria-hidden="true"
                      />
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        {alertMessage && (
          <div
            className="flex flex-col mt-4 shadow-sm cursor-pointer"
            onClick={() => setAlertExpanded(!alertExpanded)}>
            <div
              className={classNames(
                "flex items-center gap-x-6 bg-red-500 rounded-t-md px-6 py-2.5 sm:px-3.5 sm:before:flex-1",
                alertExpanded ? "" : "rounded-b-md"
              )}>
              <p className="inline-flex flex-row items-center text-sm leading-6 text-white">
                <ExclamationIcon className="h-5 w-5 mr-1.5" />
                <strong className="font-semibold ">Visitor Alert</strong>
                <svg
                  viewBox="0 0 2 2"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                  aria-hidden="true">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                Click to {alertExpanded ? "hide" : "show"}
              </p>
              <div className="flex justify-end flex-1">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
                  {alertExpanded ? (
                    <MinusIcon
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                    />
                  ) : (
                    <PlusIcon
                      className="w-5 h-5 text-white"
                      aria-hidden="true"
                    />
                  )}
                </button>
              </div>
            </div>
            {alertExpanded && (
              <div className="p-4 text-sm whitespace-pre bg-red-50 rounded-b-md">
                {alertMessage}
              </div>
            )}
          </div>
        )}
        {visitorBlocked && (
          <div className="flex flex-col mt-4 shadow-sm">
            <div
              className={classNames(
                "flex items-center justify-center gap-x-6 bg-red-500 rounded-md px-6 py-2.5 sm:px-3.5"
              )}>
              <p className="inline-flex flex-row items-center text-sm leading-6 text-white">
                <ExclamationIcon className="h-5 w-5 mr-1.5" />
                <strong className="font-semibold ">Visitor Blocked</strong>
                <svg
                  viewBox="0 0 2 2"
                  className="mx-2 inline h-0.5 w-0.5 fill-current"
                  aria-hidden="true">
                  <circle cx={1} cy={1} r={1} />
                </svg>
                Pass unable to be requested
              </p>
            </div>
          </div>
        )}
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="w-6 h-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}>
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
