import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  ExclamationIcon,
  MailIcon,
  PlusIcon,
  TicketIcon,
  TrashIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../components/Sidebar/Sidebar";
import api from "../../../utils/helpers/api";
import { ID, UpdateVisitorRequest } from "../../../utils/types";

export default function EditVisitor() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [emailInUseError, setEmailInUseError] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [emailUpdatedSuccess, setEmailUpdatedSuccess] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [sendResetEmailSuccess, setSendResetEmailSuccess] =
    useState<boolean>(false);
  const [visitor, setVisitor] = useState<any>(null);
  const [vpNumber, setVPNumber] = useState<string>("");
  const [vpApprovedCount, setVPApprovedCount] = useState<number>(0);
  const [vpStartDate, setVPStartDate] = useState<Date | null>(new Date());
  const [vpExpiryDate, setVPExpiryDate] = useState<Date | null>(new Date());
  const [initialEmail, setInitialEmail] = useState<string>("");
  const [updatedEmail, setUpdatedEmail] = useState<string>("");
  const [image, setImage] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [job, setJob] = useState<string>("");
  const [ids, setIds] = useState<ID[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [verified, setVerified] = useState<string>("false");
  const [status, setStatus] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [openDeleteVisitorModal, setOpenDeleteVisitorModal] =
    useState<boolean>(false);

  useEffect(() => {
    // Get visitor from database
    const visitorId = params.visitorId;
    if (visitorId) {
      api
        .getVisitorById(visitorId)
        .then((res) => {
          if (res.status === 200 && res.data) {
            const visitor = res.data;
            setVisitor(visitor);
            setVPNumber(visitor.vpNumber);
            setIds(visitor.ids ?? "");
            setVPApprovedCount(visitor.vpApprovedCount);
            setVPStartDate(
              visitor.vpStartDate ? new Date(visitor.vpStartDate) : null
            );
            setVPExpiryDate(
              visitor.vpExpiryDate ? new Date(visitor.vpExpiryDate) : null
            );
            setInitialEmail(visitor.email);
            setUpdatedEmail(visitor.email);
            setImage(visitor.image);
            setDob(visitor.dob);
            setGender(visitor.gender);
            setPhone(visitor.phone);
            setCompany(visitor.company);
            setJob(visitor.job);
            setFirstName(visitor.firstName);
            setLastName(visitor.lastName);
            setVerified(visitor.verified.toString());
            setStatus(visitor.status ?? 0);
            setAlertMessage(visitor.alertMessage ?? "");
          }
        })
        .catch((error) => {
          console.error(
            `Error occurred while fetching visitor with id ${visitorId}`,
            error
          );
        })
        .finally(() => setLoading(false));
    } else {
      setError(true);
    }
  }, []);

  useEffect(() => {
    if (status !== 2) setAlertMessage("");
  }, [status]);

  const updateVisitor = async () => {
    setLoading(true);
    try {
      if (params.visitorId) {
        const updatedVisitor = {
          email: updatedEmail,
          image,
          dob,
          gender,
          phone,
          company,
          job,
          firstName,
          lastName,
          ids,
          verified: verified === "true" ? true : false,
          status,
          alertMessage,
          id: params.visitorId,
        } as UpdateVisitorRequest;
        await api.updateVisitorById(params.visitorId, updatedVisitor);

        if (updatedEmail !== initialEmail) {
          setEmailUpdatedSuccess(true);
        }
        setSuccess(true);
      } else {
        console.error(
          "Error occurred while updating visitor - couldn't find the visitor's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error: any) {
      console.error("Error occurred while updating visitor - ", error);
      if (
        Object.prototype.hasOwnProperty.call(error, "response") &&
        error.response.status === 409
      ) {
        setEmailInUseError(true);
        setTimeout(() => setEmailInUseError(false), 3000);
      } else {
        setError(true);
        setTimeout(() => setError(false), 3000);
      }
    }
    setLoading(false);
  };

  const deleteVisitor = async () => {
    setLoading(true);
    setOpenDeleteVisitorModal(false);
    try {
      if (params.visitorId) {
        await api.deleteVisitor(params.visitorId);
        setDeleteSuccess(true);
        setTimeout(() => navigate("/visitor-management"), 3000);
      } else {
        console.error(
          "Error occurred while deleting visitor - couldn't find the visitor's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting visitor - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const sendPasswordResetEmail = async () => {
    setLoading(true);
    api.sendResetPasswordEmail(initialEmail).finally(() => {
      setLoading(false);
      setSendResetEmailSuccess(true);
      setTimeout(() => setSendResetEmailSuccess(false), 3000);
    });
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Visitor{" "}
                    {initialEmail && <em className="ml-1">({initialEmail})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the details of a visitor or delete them from the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="flex flex-col max-w-screen-md mt-8">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Profile
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information will be displayed publicly
                        </p>
                      </div>

                      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                        {image && (
                          <div className="w-16 h-16 overflow-hidden rounded-full sm:col-span-6">
                            <img
                              className="inline-block w-full h-full"
                              src={image}
                            />
                          </div>
                        )}

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="first-name"
                              id="first-name"
                              autoComplete="given-name"
                              disabled={loading || deleteSuccess}
                              value={firstName ?? ""}
                              onChange={(e) => setFirstName(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="last-name"
                              id="last-name"
                              autoComplete="family-name"
                              disabled={loading || deleteSuccess}
                              value={lastName ?? ""}
                              onChange={(e) => setLastName(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="email"
                              disabled={loading || deleteSuccess}
                              value={updatedEmail ?? ""}
                              onChange={(e) => setUpdatedEmail(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="dob"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date of Birth
                          </label>
                          <div className="mt-1">
                            <input
                              id="dob"
                              name="dob"
                              type="text"
                              autoComplete="dob"
                              disabled
                              value={dob ?? ""}
                              onChange={(e) => setDob(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="gender"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gender
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="gender"
                              id="gender"
                              autoComplete="gender"
                              disabled={loading || deleteSuccess}
                              value={gender ?? ""}
                              onChange={(e) => setGender(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="phone"
                              disabled={loading || deleteSuccess}
                              value={phone ?? ""}
                              onChange={(e) => setPhone(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="company"
                              id="company"
                              autoComplete="company"
                              disabled={loading || deleteSuccess}
                              value={company ?? ""}
                              onChange={(e) => setCompany(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="job"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Job
                          </label>
                          <div className="mt-1">
                            <input
                              type="text"
                              name="job"
                              id="job"
                              autoComplete="job"
                              disabled={loading || deleteSuccess}
                              value={job ?? ""}
                              onChange={(e) => setJob(e.target.value)}
                              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 ">
                          <div className="grid grid-cols-9 gap-6">
                            {ids.map((id, index) => (
                              <>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="id-type"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    ID Type
                                  </label>
                                  <select
                                    id="id-type"
                                    name="id-type"
                                    autoComplete="id-type"
                                    onChange={(e) => {
                                      const newIds = [...ids];
                                      newIds[index].idType = e.target.value as
                                        | "Unknown"
                                        | "Passport"
                                        | "Driver's License";
                                      setIds(newIds);
                                    }}
                                    value={id.idType}
                                    className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                  >
                                    <option>Driver's License</option>
                                    <option>Passport</option>
                                  </select>
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                  <label
                                    htmlFor="id-number"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    ID Number
                                  </label>
                                  <div className="mt-1">
                                    <input
                                      id="id-number"
                                      name="id-number"
                                      type="text"
                                      autoComplete="new-id-number"
                                      onChange={(e) => {
                                        const newIds = [...ids];
                                        newIds[index].idNumber = e.target.value;
                                        setIds(newIds);
                                      }}
                                      required
                                      value={id.idNumber}
                                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                                    />
                                  </div>
                                </div>
                                <div className="self-end col-span-6 sm:col-span-3">
                                  <button
                                    onClick={() => {
                                      const newIds = [...ids];
                                      newIds.splice(index, 1);
                                      setIds(newIds);
                                    }}
                                    className="flex items-center justify-center px-4 py-2 mt-auto text-sm font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                                  >
                                    <TrashIcon className="w-5 h-5 mr-1.5" />
                                    Remove ID
                                  </button>
                                </div>
                              </>
                            ))}

                            <div className="col-span-6 sm:col-span-3">
                              <button
                                onClick={() => {
                                  setIds([
                                    ...ids,
                                    { idType: "Unknown", idNumber: "" },
                                  ]);
                                }}
                                disabled={ids.length >= 2}
                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                              >
                                <PlusIcon className="w-5 h-5 mr-1.5" />
                                Add ID
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Metadata
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          This information is only viewable by admins (and
                          select officers)
                        </p>
                      </div>

                      <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="verified"
                            className="relative inline-flex items-center cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              checked={verified === "true"}
                              onChange={(e) =>
                                setVerified(String(e.target.checked))
                              }
                              id="verified"
                              className="sr-only peer"
                            />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-amber-300 rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-amber-500"></div>
                            <span className="ml-3 text-sm font-medium text-gray-900">
                              Verified
                            </span>
                          </label>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="id-type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Status
                          </label>
                          <select
                            id="id-type"
                            name="id-type"
                            autoComplete="id-type"
                            value={status}
                            disabled={loading || deleteSuccess}
                            onChange={(e) =>
                              setStatus(parseInt(e.target.value))
                            }
                            className="block w-full px-3 py-2 mt-1 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm disabled:bg-gray-100"
                          >
                            <option value={1}>Active</option>
                            <option value={2}>Flagged</option>
                            <option value={3}>Blocked</option>
                            <option value={4}>Duplicate</option>
                          </select>
                        </div>
                        {status === 2 && (
                          <div className="col-span-6 p-4 text-white rounded-md shadow-md bg-amber-500">
                            <div>
                              <label
                                htmlFor="alertMessage"
                                className="inline-flex flex-row items-center block text-sm font-medium leading-6 text-gray-900"
                              >
                                <ExclamationIcon className="h-5 w-5 mr-1.5" />
                                Alert Message
                              </label>
                              <div className="mt-2">
                                <textarea
                                  rows={4}
                                  name="alertMessage"
                                  id="alertMessage"
                                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-amber-500 sm:text-sm sm:leading-6"
                                  value={alertMessage}
                                  onChange={(e) =>
                                    setAlertMessage(e.target.value)
                                  }
                                  placeholder="Alert Message"
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Related Information
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          View information associated with this visitor
                        </p>
                      </div>
                      <div className="mt-3">
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">VP Number:</span>
                          <span className="ml-2">{vpNumber}</span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">Start Date:</span>
                          <span className="ml-2">
                            {vpStartDate
                              ? vpStartDate.toDateString()
                              : "No Date Specified"}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">End Date:</span>
                          <span className="ml-2">
                            {vpExpiryDate
                              ? vpExpiryDate.toDateString()
                              : "No Date Specified"}
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-semibold">
                            Approved Pass Count:
                          </span>
                          <span className="ml-2">
                            {vpApprovedCount} of 28 issued
                          </span>
                        </p>
                        <button
                          type="button"
                          onClick={() =>
                            navigate(
                              "/visitor-pass-requests/visitor/" +
                                params.visitorId
                            )
                          }
                          className="inline-flex items-center px-4 py-2 mt-3 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          <TicketIcon className="w-4 h-4 mr-1" />
                          View Visitor Passes
                        </button>
                      </div>
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Reset Password
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Send a reset password email to the visitor so they can
                          change their password.
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() => sendPasswordResetEmail()}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        >
                          <MailIcon className="w-4 h-4 mr-1" />
                          Reset Password
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a visitor is permanent. You will not be able to recover data once deleted"
                        open={openDeleteVisitorModal}
                        setOpen={setOpenDeleteVisitorModal}
                        title={"Are you sure you want to delete this visitor?"}
                        action={() => deleteVisitor()}
                      />
                    </div>

                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Delete Visitor
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Delete a visitor and all associated data permanently
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() => setOpenDeleteVisitorModal(true)}
                          className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        >
                          <TrashIcon className="w-4 h-4 mr-1" />
                          Delete Visitor
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting a visitor is permanent. You will not be able to recover data once deleted"
                        open={openDeleteVisitorModal}
                        setOpen={setOpenDeleteVisitorModal}
                        title={"Are you sure you want to delete this visitor?"}
                        action={() => deleteVisitor()}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex items-center justify-start">
                      <button
                        onClick={() => navigate("/visitor-management")}
                        type="button"
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateVisitor()}
                        disabled={
                          loading || !firstName || !lastName || !updatedEmail
                        }
                        className="inline-flex justify-center px-4 py-2 ml-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!firstName || !lastName || !updatedEmail) && (
                        <div className="ml-3 text-sm font-semibold text-red-500 border-none">
                          Please enter all the required fields to update visitor
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated visitor
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={emailUpdatedSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated email address
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      A verification email to verify the new email address was
                      sent to the visitor.
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setEmailUpdatedSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={sendResetEmailSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully sent password reset email
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSendResetEmailSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="w-6 h-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully deleted visitor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to visitor management portal...
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="w-6 h-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={emailInUseError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="w-6 h-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Error - that email is already in use
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again with a different email
                    </p>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setEmailInUseError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
