export const getBase64 = (file: File) =>
  new Promise((resolve) => {
    let baseURL = "";
    // Make new FileReader
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      if (reader.result) {
        baseURL = reader.result.toString();
      }
      resolve(baseURL);
    };
  });
