import { Transition } from "@headlessui/react";
import {
  CameraIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardListIcon,
  DotsCircleHorizontalIcon,
  IdentificationIcon,
  PlusIcon,
  TicketIcon,
  UserIcon,
  UsersIcon,
  XIcon,
} from "@heroicons/react/outline";
import addDays from "date-fns/addDays";
import formatISO from "date-fns/formatISO";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import api from "../../../utils/helpers/api";
import { FacePhoto } from "./FacePhoto";
import { IDDetails } from "./IDDetails";
import { IDPhoto } from "./IDPhoto";
import { PassDetails } from "./PassDetails";
import { SelectVisitor } from "./SelectVisitor";
import { SponsorDetails } from "./SponsorDetails";
import { TermsAndConditions } from "./TermsAndConditions";
import CancelModal from "../../../components/CancelModal/CancelModal";
import { ID } from "../../../utils/types";
import { Provider } from "react-redux";
import { regulaStore } from "../../../utils/regula/store";

export const CreateVisitorPass = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [invalidForm, setInvalidForm] = useState<boolean>(true);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [flaggedBlockedVisitor, setFlaggedBlockedVisitor] =
    useState<boolean>(true);
  const [visitorId, setVisitorId] = useState<string>("");
  const [facePhoto, setFacePhoto] = useState<string>("");
  const [idPhotoFront, setIDPhotoFront] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [operationalNeed, setOperationalNeed] = useState<string>("");
  const [sponsorName, setSponsorName] = useState<string>("");
  const [sponsorAsicId, setSponsorAsicId] = useState<string>("");
  const [sponsorAsicExpiryMonth, setSponsorAsicExpiryMonth] = useState<string>(
    (new Date().getMonth() + 1).toString()
  );
  const [sponsorAsicExpiryYear, setSponsorAsicExpiryYear] = useState<string>(
    (new Date().getFullYear() + 1).toString()
  );
  const [sponsorCompany, setSponsorCompany] = useState<string>("");
  const [sponsorEmail, setSponsorEmail] = useState<string>("");
  const [sponsorMobileNumber, setSponsorMobileNumber] = useState<string>("");
  const [idDocumentNumber, setIdDocumentNumber] = useState<string>("");
  const [ids, setIds] = useState<ID[]>([
    { idType: "Driver's License", idNumber: "" },
  ]);
  const [airportId, setAirportId] = useState<string>("");
  const [rangeType, setRangeType] = useState<string>("24 Hours");
  const [startTime, setStartTime] = useState<string>(
    formatISO(new Date(), { representation: "complete" }).slice(0, -9)
  );
  const [endTime, setEndTime] = useState<string>(
    formatISO(addDays(new Date(), 1), { representation: "complete" }).slice(
      0,
      -9
    )
  );
  const [checkTerms, setCheckTerms] = useState<boolean>(false);
  const [checkDampPolicy, setCheckDampPolicy] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const getStepStatus = (stepId: number) => {
    return currentStep === stepId
      ? "current"
      : currentStep > stepId
      ? "complete"
      : "upcoming";
  };

  const steps = [
    {
      id: 1,
      name: "ID Photo",
      status: getStepStatus(1),
      icon: <IdentificationIcon className="w-auto h-6" />,
      component: (
        <IDPhoto
          idPhotoFront={idPhotoFront}
          setIdPhotoFront={setIDPhotoFront}
          existingVisitorFound={!!visitorId}
          idDocumentNumber={idDocumentNumber}
          setIdDocumentNumber={(idNumber: string) => {
            setIdDocumentNumber(idNumber);
            setIds([{ ...ids[0], idNumber }]);
          }}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 2,
      name: "Select Visitor",
      status: getStepStatus(2),
      icon: <UserIcon className="w-auto h-6" />,
      component: (
        <SelectVisitor
          visitorId={visitorId}
          setVisitorId={setVisitorId}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          company={company}
          setCompany={setCompany}
          email={email}
          setEmail={setEmail}
          mobileNumber={mobileNumber}
          setMobileNumber={setMobileNumber}
          setInvalidForm={setInvalidForm}
          setFlaggedBlockedVisitor={setFlaggedBlockedVisitor}
        />
      ),
    },
    {
      id: 3,
      name: "Face Photo",
      status: getStepStatus(3),
      icon: <CameraIcon className="w-auto h-6" />,
      component: (
        <FacePhoto
          facePhoto={facePhoto}
          setFacePhoto={setFacePhoto}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 4,
      name: "Sponsor Details",
      status: getStepStatus(4),
      icon: <UsersIcon className="w-auto h-6" />,
      component: (
        <SponsorDetails
          sponsorName={sponsorName}
          setSponsorName={setSponsorName}
          sponsorAsicId={sponsorAsicId}
          setSponsorAsicId={setSponsorAsicId}
          sponsorAsicExpiryMonth={sponsorAsicExpiryMonth}
          setSponsorAsicExpiryMonth={setSponsorAsicExpiryMonth}
          sponsorAsicExpiryYear={sponsorAsicExpiryYear}
          setSponsorAsicExpiryYear={setSponsorAsicExpiryYear}
          sponsorCompany={sponsorCompany}
          setSponsorCompany={setSponsorCompany}
          sponsorEmail={sponsorEmail}
          setSponsorEmail={setSponsorEmail}
          sponsorMobileNumber={sponsorMobileNumber}
          setSponsorMobileNumber={setSponsorMobileNumber}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 5,
      name: "ID Details",
      status: getStepStatus(5),
      icon: <CheckCircleIcon className="w-auto h-6" />,
      component: (
        <IDDetails ids={ids} setIds={setIds} setInvalidForm={setInvalidForm} />
      ),
    },
    {
      id: 6,
      name: "Pass Details",
      status: getStepStatus(6),
      icon: <TicketIcon className="w-auto h-6" />,
      component: (
        <PassDetails
          operationalNeed={operationalNeed}
          setOperationalNeed={setOperationalNeed}
          airportId={airportId}
          setAirportId={setAirportId}
          rangeType={rangeType}
          setRangeType={setRangeType}
          startTime={startTime}
          setStartTime={setStartTime}
          endTime={endTime}
          setEndTime={setEndTime}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
    {
      id: 7,
      name: "Terms and Conditions",
      status: getStepStatus(7),
      icon: <ClipboardListIcon className="w-auto h-6" />,
      component: (
        <TermsAndConditions
          checkTerms={checkTerms}
          setCheckTerms={setCheckTerms}
          checkDampPolicy={checkDampPolicy}
          setCheckDampPolicy={setCheckDampPolicy}
          setInvalidForm={setInvalidForm}
        />
      ),
    },
  ];

  useEffect(() => {
    const startTimeDate = new Date(startTime);
    if (rangeType === "24 Hours") {
      setEndTime(
        formatISO(addDays(startTimeDate, 1), {
          representation: "complete",
        }).slice(0, -9)
      );
    } else if (rangeType === "7 Days") {
      setEndTime(
        formatISO(addDays(startTimeDate, 7), {
          representation: "complete",
        }).slice(0, -9)
      );
    } else if (rangeType === "28 Days") {
      setEndTime(
        formatISO(addDays(startTimeDate, 28), {
          representation: "complete",
        }).slice(0, -9)
      );
    }
  }, [startTime, rangeType]);

  useEffect(() => {
    if (idDocumentNumber) {
      // Find the visitor by their idDocumentNumber
      api.getVisitorByIdDocumentNumber(idDocumentNumber).then((res) => {
        if (res.data) {
          const visitor = res.data;
          setVisitorId(visitor.id);
          setFirstName(visitor.firstName);
          setLastName(visitor.lastName);
          setCompany(visitor.company);
          setEmail(visitor.email);
          setMobileNumber(visitor.mobileNumber);
          setFlaggedBlockedVisitor(
            visitor.status === 2 || visitor.status === 3
          );
        }
      });
    } else {
      setVisitorId("");
      setFirstName("");
      setLastName("");
      setCompany("");
      setEmail("");
      setMobileNumber("");
      setFlaggedBlockedVisitor(false);
    }
  }, [idDocumentNumber]);

  const createVisitorPass = async () => {
    setLoading(true);
    try {
      let visitorPassId = "";
      if (visitorId) {
        const visitorPassRes = await api.createVisitorPassForExistingVisitor({
          visitorId,
          firstName,
          lastName,
          email,
          mobileNumber,
          company,
          facePhoto,
          idPhotoFront,
          sponsorAsicId,
          sponsorName,
          sponsorAsicExpiryMonth,
          sponsorAsicExpiryYear,
          sponsorCompany,
          sponsorEmail,
          sponsorMobileNumber,
          operationalNeed,
          rangeType,
          startTime,
          endTime,
          airportId,
          ids,
        });

        if (visitorPassRes.data) {
          visitorPassId = visitorPassRes.data.id;
        }
      } else {
        const visitorPassRes = await api.createVisitorPassForNewVisitor({
          firstName,
          lastName,
          email,
          mobileNumber,
          company,
          facePhoto,
          idPhotoFront,
          sponsorAsicId,
          sponsorName,
          sponsorAsicExpiryMonth,
          sponsorAsicExpiryYear,
          sponsorCompany,
          sponsorEmail,
          sponsorMobileNumber,
          operationalNeed,
          rangeType,
          startTime,
          endTime,
          airportId,
          ids,
        });

        if (visitorPassRes.data) {
          visitorPassId = visitorPassRes.data.id;
        }
      }

      setSuccess(true);
      setTimeout(() => {
        navigate(`/visitor-pass-requests/${visitorPassId}`);
      }, 3000);
    } catch (error) {
      console.error("Error while creating visitor pass - ", error);
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
    setLoading(false);
  };

  return (
    <Provider store={regulaStore}>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          <div className="px-4 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <h1 className="flex-row items-center hidden pt-2 text-2xl font-semibold md:flex">
              <PlusIcon className="h-7 w-auto mr-1.5" />
              Create Visitor Pass
            </h1>
          </div>
          <nav
            aria-label="Progress"
            className="hidden px-4 py-3 mx-auto overflow-x-auto scrollbar scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-100 md:flex max-w-7xl sm:px-6 lg:px-8"
          >
            <ol
              role="list"
              className="bg-white border border-gray-300 divide-y divide-gray-300 md:flex md:divide-y-0"
            >
              {steps.map((step, stepIdx) => (
                <li
                  key={step.name}
                  className="relative cursor-pointer md:flex md:flex-1"
                >
                  {step.status === "complete" ? (
                    <div className="flex items-center w-full group">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full bg-amber-600 group-hover:bg-amber-800">
                          <CheckIcon
                            className="w-6 h-6 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  ) : step.status === "current" ? (
                    <div
                      className="flex items-center px-6 py-4 text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 rounded-full bg-amber-600">
                        <span className="text-white">{step.icon}</span>
                      </span>
                      <span className="ml-4 text-sm font-medium text-amber-600">
                        {step.name}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-center group">
                      <span className="flex items-center px-6 py-4 text-sm font-medium">
                        <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <span className="text-gray-500 group-hover:text-gray-900">
                            {step.icon}
                          </span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  )}
                  {stepIdx !== steps.length - 1 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div
                        className="absolute top-0 right-0 hidden w-5 h-full md:block"
                        aria-hidden="true"
                      >
                        <svg
                          className="w-full h-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
          <div className="py-2 md:py-4">
            <div className="px-4 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8 lg:grid lg:grid-cols-12 lg:gap-4">
              <main className="lg:col-span-12 xl:col-span-10">
                <h2 className="flex flex-row items-center px-4 mb-4 text-2xl font-semibold md:text-xl sm:px-0">
                  <span className="h-6 w-auto mr-1.5">
                    {steps[currentStep - 1].icon}
                  </span>
                  {steps[currentStep - 1].name}
                </h2>
                <div className="md:hidden">
                  <div className="px-4 mt-6 sm:px-0" aria-hidden="true">
                    <div className="overflow-hidden bg-gray-200 rounded-full">
                      <div
                        className="h-2 rounded-full bg-amber-500"
                        style={{
                          width: `${(currentStep * 100) / steps.length}%`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                {steps[currentStep - 1].component ?? ""}
              </main>
              <div className="col-span-3 px-4 mt-4 mb-2 sm:px-0 lg:my-0">
                <button
                  type="submit"
                  onClick={() =>
                    currentStep === steps.length
                      ? createVisitorPass()
                      : setCurrentStep(currentStep + 1)
                  }
                  disabled={
                    loading ||
                    invalidForm ||
                    (flaggedBlockedVisitor && currentStep === 2) ||
                    success
                  }
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-50"
                >
                  {currentStep === steps.length ? "Submit" : "Next"}
                </button>
              </div>
              {currentStep > 1 && (
                <div className="col-span-3 px-4 my-2 sm:px-0 lg:my-0">
                  <button
                    type="submit"
                    disabled={success}
                    onClick={() =>
                      currentStep === 1
                        ? navigate("/visitor-pass-requests")
                        : setCurrentStep(currentStep - 1)
                    }
                    className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black border border-transparent rounded-md shadow-sm hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:opacity-50"
                  >
                    Previous
                  </button>
                </div>
              )}
              <div className="col-span-3 px-4 my-2 sm:px-0 lg:my-0">
                <button
                  type="submit"
                  disabled={success}
                  onClick={() => setOpenCancelModal(true)}
                  className="flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-600 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50"
                >
                  Cancel
                </button>
              </div>
              <CancelModal
                open={openCancelModal}
                setOpen={setOpenCancelModal}
                title={"Cancel Visitor Pass Request"}
                description={
                  "Are you sure you want to cancel this visitor pass request? You will not be able to recover your progress if you cancel."
                }
                action={() => {
                  setOpenCancelModal(false);
                  navigate("/visitor-pass-requests");
                }}
              />
              {flaggedBlockedVisitor && currentStep === 2 ? (
                <div className="flex flex-row col-span-12 px-4 mt-2 text-sm font-medium text-red-500 sm:px-0 sm:mt-0">
                  <div className="my-1">
                    Please choose a visitor that has not been flagged or blocked
                    (or create a new visitor) to continue
                  </div>
                </div>
              ) : (
                invalidForm && (
                  <div className="flex flex-row col-span-12 px-4 mt-2 text-sm font-medium text-red-500 sm:px-0 sm:mt-0">
                    <div className="my-1">
                      Please complete all required information (*) to continue
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
          <div
            aria-live="assertive"
            className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
          >
            <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
              {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
              <Transition
                show={loading}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <DotsCircleHorizontalIcon
                          className="w-6 h-6 text-blue-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          Submitting...
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Please wait
                        </p>
                      </div>
                      <div className="flex flex-shrink-0 ml-4">
                        <button
                          type="button"
                          className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <Transition
                show={success}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <CheckCircleIcon
                          className="w-6 h-6 text-green-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          Successfully requested a visitor pass
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Please wait for it to be approved
                        </p>
                      </div>
                      <div className="flex flex-shrink-0 ml-4">
                        <button
                          type="button"
                          className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          onClick={() => {
                            setSuccess(false);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
              <Transition
                show={error}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
                  <div className="p-4">
                    <div className="flex items-start">
                      <div className="flex-shrink-0">
                        <XIcon
                          className="w-6 h-6 text-red-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 w-0 flex-1 pt-0.5">
                        <p className="text-sm font-medium text-gray-900">
                          An error occurred
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Please try again or contact support
                        </p>
                      </div>
                      <div className="flex flex-shrink-0 ml-4">
                        <button
                          type="button"
                          className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                          onClick={() => {
                            setError(false);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <XIcon className="w-5 h-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </main>
      </div>
    </Provider>
  );
};
