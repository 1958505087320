/* eslint-disable @typescript-eslint/no-explicit-any */
class RegulaEvents {
  onNotificationOpticalCallback: any;
  onImageReadyCallback: any;
  onNotificationRFIDCallback: any;
  onProcessingFinishedCallback: any;
  onProcessingStartedCallback: any;
  onResultReadyCallback: any;
  onResultReadyXMLCallback: any;
  onRFIDRequestCallback: any;
  onSystemNotificationCallback: any;
  onExtPortraitRequestCallback: any;

  constructor() {
    this.onNotificationOpticalCallback = null;
    this.onImageReadyCallback = null;
    this.onNotificationRFIDCallback = null;
    this.onProcessingFinishedCallback = null;
    this.onProcessingStartedCallback = null;
    this.onResultReadyCallback = null;
    this.onResultReadyXMLCallback = null;
    this.onRFIDRequestCallback = null;
    this.onSystemNotificationCallback = null;
    this.onExtPortraitRequestCallback = null;
  }
}

export default new RegulaEvents();
