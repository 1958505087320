import { addDays, sub, subMonths, subYears } from "date-fns";
import { useEffect, useState } from "react";

interface DateRangePickerProps {
  disabled?: boolean;
  updateDates: (newStartDate: string, newEndDate: string) => void;
}

export default function DateRangePicker(props: DateRangePickerProps) {
  const [shouldSubmit, setShouldSubmit] = useState<boolean>(true);

  const [startDate, setStartDate] = useState<string>(
    subMonths(new Date(), 1).toISOString().slice(0, -8)
  );
  const [endDate, setEndDate] = useState<string>(
    addDays(new Date(), 1).toISOString().slice(0, -8)
  );

  useEffect(() => {
    if (!shouldSubmit) return;
    props.updateDates(startDate, endDate);
    setShouldSubmit(false);
  }, [startDate, endDate, shouldSubmit]);

  const handleSetStartDate = (event: any) => {
    setStartDate(event.target.value);
  };

  const handleSetEndDate = (event: any) => {
    setEndDate(event.target.value);
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateDates(startDate, endDate);
      }}
      className="grid grid-cols-1 gap-y-1 gap-x-1 sm:grid-cols-6 items-center rounded-lg overflow-hidden mb-2 justify-between max-w-2xl">
      <div className="col-span-2">
        <label
          htmlFor="start-date"
          className="block text-left text-sm font-medium text-gray-700">
          Start Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="start-date"
            id="start-date"
            autoComplete="start-date"
            disabled={props.disabled}
            value={startDate ?? ""}
            onChange={handleSetStartDate}
            onBlur={() => setShouldSubmit(true)}
            className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full text-gray-600 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
          />
        </div>
      </div>

      <div className="col-span-2">
        <label
          htmlFor="end-date"
          className="block text-left text-sm font-medium text-gray-700">
          End Date
        </label>
        <div className="mt-1">
          <input
            type="datetime-local"
            name="end-date"
            id="end-date"
            autoComplete="end-date"
            disabled={props.disabled}
            value={endDate ?? ""}
            onChange={handleSetEndDate}
            onBlur={() => setShouldSubmit(true)}
            className="shadow-sm focus:ring-amber-500 focus:border-amber-500 block w-full text-gray-600 sm:text-sm border-gray-300 rounded-md disabled:bg-gray-100"
          />
        </div>
      </div>
    </form>
  );
}
