import { SearchIcon } from "@heroicons/react/outline";
import debounce from "lodash.debounce";
import React from "react";
import { useEffect, useState } from "react";

interface SearchOption {
  value: string;
  name: string;
}

interface SearchFormProps {
  searchOptions: SearchOption[];
  disabled?: boolean;
  updateSearchQueryAndField: (searchQuery: string, searchField: string) => void;
  initialSearchQuery?: string;
  initialSearchField?: string;
  debounced?: boolean;
}

export default function SearchForm(props: SearchFormProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>(
    props.searchOptions[0].value
  );

  useEffect(() => {
    if (props.initialSearchQuery) {
      setSearchQuery(props.initialSearchQuery);
    }
    if (props.initialSearchField) {
      setSearchField(props.initialSearchField);
    }
  }, [props.initialSearchQuery, props.initialSearchField]);

  useEffect(() => {
    if (props.debounced && searchQuery !== "" && searchField !== "") {
      debouncedUpdateSearchQueryAndField(searchQuery, searchField);
    }
  }, [searchQuery, searchField]);

  const debouncedUpdateSearchQueryAndField = React.useRef(
    debounce((newSearchQuery, newSearchField) => {
      props.updateSearchQueryAndField(newSearchQuery, newSearchField);
    }, 1000)
  ).current;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        props.updateSearchQueryAndField(searchQuery, searchField);
      }}
      className="grid grid-cols-1 gap-y-1 gap-x-1 sm:grid-cols-6 items-center rounded-lg overflow-hidden mb-4 justify-between max-w-2xl"
    >
      <input
        className="col-span-1 sm:col-span-2 text-gray-900 text-sm bg-white flex-grow b-none px-3 border-gray-300 focus:ring-stone-600 focus:border-stone-600 rounded-lg"
        type="text"
        placeholder="Search (by field)"
        onChange={(e) => setSearchQuery(e.target.value)}
        value={searchQuery}
        disabled={props.disabled}
      />
      <select
        onChange={(e) => setSearchField(e.target.value)}
        value={searchField}
        className="col-span-1 sm:col-span-2 text-sm text-gray-500 outline-none border-gray-300 px-4 pr-8 py-2 rounded-lg focus:ring-stone-600 focus:border-stone-600"
      >
        {props.searchOptions.map((o) => {
          return <option value={o.value}>{o.name}</option>;
        })}
      </select>
      <button
        type="submit"
        disabled={props.disabled}
        className="col-span-1 sm:col-span-1 flex sm:inline-flex flex-row items-center bg-stone-600 hover:bg-stone-700 disabled:opacity-60 disabled:hover:bg-stone-600 text-white text-sm font-medium rounded-lg px-4 py-2"
      >
        <SearchIcon className="h-5 w-5 mr-1.5" />
        Search
      </button>
    </form>
  );
}
