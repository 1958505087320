import { createSlice, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const regulaSlice = createSlice({
  name: "regula",
  initialState: {
    loading: false,
    images: [] as string[],
    data: [
      {
        field: "",
        value: "",
      },
    ] as { field: string; value: string }[],
  },
  reducers: {
    startProcessing: (state) => {
      state.loading = true;
    },
    clearStoreResults: (state) => {
      state.images = [];
      state.data = [];
    },
    finishProcessing: (state) => {
      state.loading = false;
    },
    addImage: (state, action) => {
      state.images.push(action.payload);
    },
    addData: (state, action) => {
      // Check that the field is not already in the array
      const index = state.data.findIndex(
        (item) => item.field === action.payload.field
      );

      if (index !== -1) {
        // If it is, replace the value
        state.data[index].value = action.payload.value;
        return;
      }

      // Otherwise, add it to the array
      state.data.push(action.payload);
    },
  },
});

export const {
  startProcessing,
  clearStoreResults,
  finishProcessing,
  addImage,
  addData,
} = regulaSlice.actions;

export const regulaStore = configureStore({
  reducer: regulaSlice.reducer,
});

type RootState = ReturnType<typeof regulaStore.getState>;
type AppDispatch = typeof regulaStore.dispatch;

export const useRegulaDispatch: () => AppDispatch = useDispatch;
export const useRegulaSelector: TypedUseSelectorHook<RootState> = useSelector;
