import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
interface TermsAndConditionsProps {
  checkTerms: boolean;
  setCheckTerms: (newCheckTerms: boolean) => void;
  checkDampPolicy: boolean;
  setCheckDampPolicy: (newCheckDampPolicy: boolean) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}
export const TermsAndConditions = (props: TermsAndConditionsProps) => {
  useEffect(() => {
    props.setInvalidForm(!props.checkTerms || !props.checkDampPolicy);
  }, [props.checkTerms, props.checkDampPolicy]);
  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  return (
    <div className="mt-4 sm:px-0 px-4 max-w-screen-sm">
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="terms"
                aria-describedby="terms-description"
                name="terms"
                type="checkbox"
                value={String(props.checkTerms)}
                onChange={(e) => props.setCheckTerms(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="font-base text-gray-600">
                By checking this box, I agree to Visitor Pass'{" "}
                <a
                  target={"_blank"}
                  href={"https://gate99.au/terms"}
                  className="text-amber-500 hover:text-amber-600 font-semibold cursor-pointer hover:underline"
                >
                  terms and conditions
                  <ExternalLinkIcon className="inline-block h-4 w-4 ml-0.5" />
                </a>{" "}
                and{" "}
                <a
                  target={"_blank"}
                  href={"https://gate99.au/privacy"}
                  className="text-amber-500 hover:text-amber-600 font-semibold cursor-pointer hover:underline"
                >
                  privacy policy
                  <ExternalLinkIcon className="inline-block h-4 w-4 ml-0.5" />
                </a>
                {showAsteriskIfEmpty(props.checkTerms ? "non-empty" : "")}
              </label>
            </div>
          </div>
        </div>
        <div className="col-span-6">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="damp-policy"
                aria-describedby="damp-policy-description"
                name="damp-policy"
                type="checkbox"
                value={String(props.checkDampPolicy)}
                onChange={(e) => props.setCheckDampPolicy(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-amber-500 focus:ring-amber-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="damp-policy" className="font-base text-gray-600">
                By checking this box, I acknowledge and declare that I have an
                operational need and understand my obligations entering a secure
                area at Bendigo Airport.
                {showAsteriskIfEmpty(props.checkDampPolicy ? "non-empty" : "")}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
