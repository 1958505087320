import { CameraIcon, RefreshIcon } from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
interface FacePhotoProps {
  facePhoto: string;
  setFacePhoto: (newFacePhoto: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}
export const FacePhoto = (props: FacePhotoProps) => {
  const [error, setError] = useState<string>("");
  const [facingMode, setFacingMode] = useState<string>("user");
  const webcamRef = useRef<any>();
  useEffect(() => {
    props.setInvalidForm(!props.facePhoto);
  }, [props.facePhoto]);
  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };
  const capturePhoto = () => {
    try {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          props.setFacePhoto(imageSrc);
        } else {
          setError("Loading webcam... please wait.");
        }
      } else {
        setError("Loading webcam... please wait.");
      }
    } catch (error) {
      setError(
        "An unknown error occurred. Please try again or contact support."
      );
    }
    setTimeout(() => setError(""), 2000);
  };
  return (
    <div className="mt-4 sm:px-0 px-4 max-w-screen-sm">
      <div className="">
        <label className="inline-block mb-2 text-gray-500">
          Take a photo of your face
          {showAsteriskIfEmpty(props.facePhoto)}
        </label>
        <div className="flex w-full">
          <div className="relative py-2 text-left overflow-hidden transform transition-all sm:max-w-lg sm:w-full">
            <div className="sm:flex sm:items-start">
              <div className="text-center mt-0 sm:text-left">
                <div className="mt-2 rounded-md">
                  {props.facePhoto ? (
                    <div className="flex flex-col items-center justify-center">
                      <img
                        className="w-full h-full rounded-md"
                        src={props.facePhoto}
                      />
                    </div>
                  ) : (
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      width={1000}
                      height={1000}
                      videoConstraints={{ facingMode }}
                    />
                  )}
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Make sure your face is fully visible inside the frame
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex">
              {props.facePhoto ? (
                <button
                  type="button"
                  className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                  onClick={() => props.setFacePhoto("")}
                >
                  <CameraIcon className="h-5 w-5" />
                  <span className="ml-1.5">Retake</span>
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className="items-center w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-500 text-base font-medium text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => capturePhoto()}
                  >
                    <CameraIcon className="h-5 w-5" />
                    <span className="ml-1.5">Capture</span>
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-stone-500 hover:bg-stone-600 text-base font-medium text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-500 mt-2 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() =>
                      facingMode === "user"
                        ? setFacingMode("environment")
                        : setFacingMode("user")
                    }
                  >
                    <RefreshIcon className="h-5 w-5" />
                    <span className="ml-1.5">Flip</span>
                  </button>
                </>
              )}
            </div>
            {error && <div className="mt-4 text-red-600 text-sm">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
