import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import {
  CreateAirportRequest,
  CreateUserRequest,
  CreateVisitorRequest,
  UpdateAirportRequest,
  UpdateUserRequest,
  UpdateVisitorRequest,
} from "../types";

class Api {
  token: string | null;
  portalToken: string | null;
  client: AxiosInstance;
  apiBaseUrl: string;

  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_API_BASE_URL ?? "";
    this.token = localStorage.getItem("token");
    this.portalToken = localStorage.getItem("portal-token");

    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );
  }

  updateClient = (newToken: string) => {
    this.token = newToken;

    // Update API
    const headers: AxiosRequestHeaders = {
      Accept: "application/json",
    };

    if (this.token) {
      headers.Authorization = `Bearer ${this.token}`;
    }

    this.client = axios.create({
      baseURL: this.apiBaseUrl,
      timeout: 31000,
      headers: headers,
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => {
        if ([401, 403].includes(error.response.status)) {
          if (!window.location.href.includes("/signin")) {
            window.location.href = "/signout";
          }
        }
        return Promise.reject(error);
      }
    );
    return this.client;
  };

  // Required endpoints
  // Users
  getUserById = (id: string) => {
    return this.client.get(`/users/${id}`);
  };

  getUsers = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/users`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  createUser = (newUser: CreateUserRequest) => {
    return this.client.post(`users/`, newUser);
  };

  login = (username: string, password: string) => {
    return this.client.post(`users/login`, { username, password });
  };

  updateUserById = (id: string, updatedUser: UpdateUserRequest) => {
    return this.client.patch(`users/${id}`, updatedUser);
  };

  deleteUser = (id: string) => {
    return this.client.delete(`users/${id}`);
  };

  // Visitors
  getVisitors = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    status: string
  ) => {
    return this.client.get(`/visitors`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status,
      },
    });
  };

  getVisitorById = (id: string) => {
    return this.client.get(`/visitors/${id}`);
  };

  getVisitorByIdDocumentNumber = (id: string) => {
    return this.client.get(`/visitors/id-document/${id}`);
  };

  createVisitor = (newVisitor: CreateVisitorRequest) => {
    return this.client.post(`/visitors`, newVisitor);
  };

  updateVisitorById = (id: string, updatedVisitor: UpdateVisitorRequest) => {
    return this.client.patch(`visitors/${id}`, updatedVisitor);
  };

  deleteVisitor = (id: string) => {
    return this.client.delete(`visitors/${id}`);
  };

  sendResetPasswordEmail = (email: string) =>
    this.client.post(`/visitors/email/reset-password`, { email });

  validateVisitorAddress = (address: string) =>
    this.client.post(`/visitors/address/validate`, { address });

  // Visitor Passes
  getVisitorPassRequestsForVisitor = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    visitorId: string,
    status: string
  ) => {
    return this.client.get(`/visitor-passes/visitor/${visitorId}`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status,
      },
    });
  };

  getVisitorPassRequestsForAirport = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    airportId: string,
    status: string
  ) => {
    return this.client.get(`/visitor-passes/airport/${airportId}`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status,
      },
    });
  };

  getVisitorPassRequests = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string,
    status: string,
    fromDate?: string,
    toDate?: string
  ) => {
    return this.client.get(`/visitor-passes`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status,
        fromDate,
        toDate,
      },
    });
  };

  getVisitorPassById = (id: string) => {
    return this.client.get(`/visitor-passes/${id}`);
  };

  getVisitorPassFileByPath = (visitorPassId: string, filePath: string) => {
    return this.client.get(`/visitor-passes/storage/${visitorPassId}`, {
      params: {
        filePath,
      },
    });
  };

  createVisitorPassForNewVisitor = (data: any) =>
    this.client.post(`/visitor-passes/new-visitor`, data);

  createVisitorPassForExistingVisitor = (data: any) =>
    this.client.post(`/visitor-passes/existing-visitor`, data);

  sendBulkSmsToVisitorPasses = (
    visitorPassIds: any[],
    messageContent: string
  ) =>
    this.client.post(`/visitor-passes/sms/bulk`, {
      visitorPassIds,
      messageContent,
    });

  exportVisitorPassesToCsv = (visitorPassIds: string[]) => {
    return this.client.post(`/visitor-passes/export/csv`, {
      visitorPassIds,
    });
  };

  updateVisitorPass = (id: any, data: any) =>
    this.client.patch(`/visitor-passes/${id}`, data);

  deleteVisitorPassById = (id: any) =>
    this.client.delete(`/visitor-passes/${id}`);

  approveVisitorPassById = (id: string) => {
    return this.client.post(`/visitor-passes/approve/${id}`);
  };

  rejectVisitorPassById = (id: string, rejectReason: string) => {
    return this.client.post(`/visitor-passes/reject/${id}`, {
      rejectReason: rejectReason,
    });
  };

  cancelVisitorPassById = (id: string, cancelReason: string) => {
    return this.client.post(`/visitor-passes/cancel/${id}`, {
      cancelReason: cancelReason,
    });
  };

  printVisitorPassById = (id: string, startTime?: string, endTime?: string) => {
    return this.client.post(`/visitor-passes/print/${id}`, {
      startTime: startTime,
      endTime: endTime,
    });
  };

  resendSponsorVerificationRequest = (visitorPassId: string) => {
    return this.client.post(`/visitor-passes/sponsor/resend/${visitorPassId}`);
  };

  approveVisitorPassSponsorById = (
    id: string,
    sponsorEmail: string,
    sponsorMobileNumber: string
  ) =>
    this.client.post(`/visitor-passes/sponsor/approve/${id}`, {
      sponsorEmail,
      sponsorMobileNumber,
    });

  // Airports
  getAirports = (
    page: number,
    size: number,
    searchQuery: string,
    searchField: string,
    sortOrder: string,
    sortField: string
  ) => {
    return this.client.get(`/airports`, {
      params: {
        page,
        size,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
      },
    });
  };

  getAirportById = (id: string) => {
    return this.client.get(`/airports/${id}`);
  };

  getUserAirport = () => {
    return this.client.get(`/airports/user`);
  };

  createAirport = (newAirport: CreateAirportRequest) => {
    return this.client.post(`/airports`, newAirport);
  };

  updateAirportById = (id: string, updatedAirport: UpdateAirportRequest) => {
    return this.client.patch(`airports/${id}`, updatedAirport);
  };

  deleteAirport = (id: string) => {
    return this.client.delete(`airports/${id}`);
  };

  enableAirport = (id: string) => {
    return this.client.post(`airports/enable/${id}`);
  };

  disableAirport = (id: string) => {
    return this.client.post(`airports/disable/${id}`);
  };

  checkIfAirportCanBeDeleted = (id: string) => {
    return this.client.get(`airports/delete/check/${id}`);
  };
}

export default new Api();
