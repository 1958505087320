import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  BanIcon,
  CheckCircleIcon,
  CheckIcon,
  TicketIcon,
  XIcon,
} from "@heroicons/react/outline";
import { PencilIcon, TrashIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import Sidebar from "../../../components/Sidebar/Sidebar";
import api from "../../../utils/helpers/api";
import { classNames, iannaTimeZones } from "../../../utils/helpers/misc";
import { UpdateAirportRequest } from "../../../utils/types";
import G99Favicon from "../../../assets/img/g99-favicon.png";
import { getBase64 } from "../../../utils/helpers/images";

export default function EditAirport() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false);
  const [disableSuccess, setDisableSuccess] = useState<boolean>(false);
  const [enableSuccess, setEnableSuccess] = useState<boolean>(false);
  const [airport, setAirport] = useState<any>(null);
  const [airportDeletable, setAirportDeletable] = useState<boolean>(false);
  const [airportLogoUrl, setAirportLogoUrl] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [airportCode, setAirportCode] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("");
  const [currentTime, setCurrentTime] = useState<string>("");
  const [openDisableAirportModal, setOpenDisableAirportModal] =
    useState<boolean>(false);
  const [openDeleteAirportModal, setOpenDeleteAirportModal] =
    useState<boolean>(false);
  const [openEnableAirportModal, setOpenEnableAirportModal] =
    useState<boolean>(false);

  useEffect(() => {
    // Get airport from database
    const airportId = params.airportId;
    if (airportId) {
      fetchAirport(airportId);
      checkIfAirportCanBeDeleted(airportId);
    } else {
      setError(true);
    }
  }, []);

  const fetchAirport = async (airportId: string) => {
    api
      .getAirportById(airportId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const airport = res.data;
          setAirport(airport);
          setName(airport.name);
          setAirportCode(airport.code);
          setAirportLogoUrl(airport.logo);
          setTimezone(airport.timezone);
        }
      })
      .catch((error) => {
        console.error(
          `Error occurred while fetching airport with id ${airportId}`,
          error
        );
      })
      .finally(() => setLoading(false));
  };

  const checkIfAirportCanBeDeleted = async (airportId: string) => {
    api
      .checkIfAirportCanBeDeleted(airportId)
      .then((res) => {
        setAirportDeletable(res.data);
      })
      .catch((error) => {
        console.error(
          `Error occurred while check if airport deletable with id ${airportId}`,
          error
        );
      });
  };

  useEffect(() => {
    let localizedTimeString = "Invalid Timezone - couldn't find current time";
    try {
      localizedTimeString = new Date().toLocaleString("en-US", {
        timeZone: timezone,
      });
    } catch (error) {
      console.error("Error while setting currentTime -", error);
    }

    setCurrentTime(localizedTimeString);
  }, [timezone]);

  const updateAirport = async () => {
    setLoading(true);
    try {
      if (params.airportId) {
        const updatedAirport = {
          name,
          code: airportCode,
          timezone,
          id: params.airportId,
        } as UpdateAirportRequest;

        await api.updateAirportById(params.airportId, updatedAirport);
        setSuccess(true);
      } else {
        console.error(
          "Error occurred while updating airport - couldn't find the airport's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while updating airport - ", error);
      setError(true);
    }
    setLoading(false);
  };

  const updateAirportLogo = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setUpdateInProgress(true);

    try {
      if (e.target.files && e.target.files.length > 0 && params.airportId) {
        const imageFile = e.target.files[0];
        const base64 = (await getBase64(imageFile)) as string;
        const updatedAirport = await api.updateAirportById(params.airportId!, {
          logo: base64,
        });
        setAirport(updatedAirport.data);
        setAirportLogoUrl(URL.createObjectURL(e.target.files[0]));
        setSuccess(true);
        setTimeout(() => setSuccess(false), 3000);
      } else {
        setError(true);
        setTimeout(() => setError(false), 3000);
      }
    } catch (error) {
      console.error("Error occurred while updating airport logo - ", error);
      setError(true);
      setTimeout(() => setError(false), 3000);
    }
    setLoading(false);
    setUpdateInProgress(false);
  };

  const enableAirport = async () => {
    setLoading(true);
    setOpenEnableAirportModal(false);
    try {
      if (params.airportId) {
        await api.enableAirport(params.airportId);
        setEnableSuccess(true);
        fetchAirport(params.airportId);
      } else {
        console.error(
          "Error occurred while enabling airport - couldn't find the airport's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while enabling airport - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const disableAirport = async () => {
    setLoading(true);
    setOpenDisableAirportModal(false);
    try {
      if (params.airportId) {
        await api.disableAirport(params.airportId);
        setDisableSuccess(true);
        fetchAirport(params.airportId);
      } else {
        console.error(
          "Error occurred while disabling airport - couldn't find the airport's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while disabling airport - ", error);
      setError(true);
      setLoading(false);
    }
  };

  const deleteAirport = async () => {
    setLoading(true);
    setOpenDeleteAirportModal(false);
    try {
      if (params.airportId) {
        await api.deleteAirport(params.airportId);
        setDeleteSuccess(true);
        setTimeout(() => navigate("/airport-management"), 3000);
      } else {
        console.error(
          "Error occurred while deleting airport - couldn't find the airport's id from the URL parameters"
        );
        setError(true);
      }
    } catch (error) {
      console.error("Error occurred while deleting airport - ", error);
      setError(true);
      setLoading(false);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <PencilIcon className="h-7 w-7 mr-1.5" />
                    Edit Airport {name && <em className="ml-1">({name})</em>}
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Edit the name and timezone of an airport or delete it from
                    the system
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>

              <div className="mt-8 flex flex-col max-w-screen-md">
                <div className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8 divide-y divide-gray-200">
                    <div className="text-sm">
                      <span className="font-semibold">Status: </span>
                      {airport && airport.hasOwnProperty("enabled") && (
                        <span>
                          {airport.enabled ? (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Enabled
                            </div>
                          ) : (
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Disabled
                            </div>
                          )}
                        </span>
                      )}
                    </div>
                    <div className="pt-4">
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="col-span-4">
                          <label
                            htmlFor="airport-logo"
                            className="block text-sm font-medium text-gray-700 mb-2"
                          >
                            Logo
                          </label>
                          <label
                            className={classNames(
                              "flex flex-col cursor-pointer w-fit opacity-70 hover:opacity-100"
                            )}
                          >
                            {airportLogoUrl ? (
                              <div className="flex flex-col h-16 w-auto overflow-hidden">
                                <img
                                  className="inline-block h-16 w-auto"
                                  src={airportLogoUrl}
                                />
                              </div>
                            ) : (
                              <div className="flex flex-col h-16 w-16 overflow-hidden rounded-md">
                                <img
                                  className="inline-block h-full w-full"
                                  src={G99Favicon}
                                />
                              </div>
                            )}
                            <input
                              type="file"
                              disabled={loading}
                              className="opacity-0 hidden"
                              onChange={updateAirportLogo}
                            />
                          </label>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="name"
                              disabled={
                                loading || disableSuccess || deleteSuccess
                              }
                              value={name ?? ""}
                              onChange={(e) => setName(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="airport-code"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Airport Code
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <input
                              type="text"
                              name="airport-code"
                              id="airport-code"
                              autoComplete="airport-code"
                              disabled={
                                loading || disableSuccess || deleteSuccess
                              }
                              value={airportCode ?? ""}
                              onChange={(e) => setAirportCode(e.target.value)}
                              className="flex-1 focus:ring-amber-500 focus:border-amber-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300 disabled:bg-gray-100"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="timezone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Timezone
                          </label>
                          <select
                            id="timezone"
                            name="timezone"
                            autoComplete="timezone"
                            disabled={
                              loading || disableSuccess || deleteSuccess
                            }
                            onChange={(e) => setTimezone(e.target.value)}
                            value={timezone}
                            className="mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                          >
                            {iannaTimeZones.map((tz) => {
                              return <option>{tz}</option>;
                            })}
                          </select>
                        </div>
                        <div className="sm:col-span-4">
                          <div className="block text-sm">
                            <span className="font-semibold">Current Time:</span>{" "}
                            {currentTime}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Related Information
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          View information associated with this airport
                        </p>
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={() =>
                            navigate(
                              "/visitor-pass-requests/airport/" +
                                params.airportId
                            )
                          }
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                          <TicketIcon className="h-4 w-4 mr-1" />
                          View Visitor Passes
                        </button>
                      </div>
                      <div className="mt-6">
                        <h4 className="text-md leading-6 font-medium text-gray-900">
                          Users
                        </h4>
                        {airport &&
                        airport.hasOwnProperty("admins") &&
                        airport.hasOwnProperty("officers") &&
                        airport.admins.length === 0 &&
                        airport.officers.length === 0 ? (
                          <div className="mt-1 text-sm text-gray-500">
                            No users for this airport. To add some, create/edit
                            a user and give them the Airport Admin/Officer role
                            for this airport
                          </div>
                        ) : (
                          <div className="my-3 grid grid-cols-1 gap-4 sm:grid-cols-2">
                            {airport &&
                              airport.hasOwnProperty("admins") &&
                              airport.admins.length > 0 &&
                              airport.admins.map((admin: any) => {
                                return (
                                  <div
                                    key={admin.id}
                                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                                  >
                                    <div className="min-w-0 flex-1">
                                      <Link
                                        to={"/user-management/edit/" + admin.id}
                                        className="focus:outline-none"
                                      >
                                        <span
                                          className="absolute inset-0"
                                          aria-hidden="true"
                                        />
                                        <p className="text-sm font-medium text-gray-900">
                                          {admin.firstName +
                                            " " +
                                            admin.lastName}
                                          <span className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                                            Airport Admin
                                          </span>
                                        </p>
                                        <p className="truncate text-sm text-gray-500">
                                          {admin.email}
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                            {airport &&
                              airport.hasOwnProperty("officers") &&
                              airport.officers.length > 0 &&
                              airport.officers.map((officer: any) => {
                                return (
                                  <div
                                    key={officer.id}
                                    className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                                  >
                                    <div className="min-w-0 flex-1">
                                      <Link
                                        to={
                                          "/user-management/edit/" + officer.id
                                        }
                                        className="focus:outline-none"
                                      >
                                        <span
                                          className="absolute inset-0"
                                          aria-hidden="true"
                                        />
                                        <p className="text-sm font-medium text-gray-900">
                                          {officer.firstName +
                                            " " +
                                            officer.lastName}
                                          <span className="ml-1.5 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-stone-100 text-stone-800">
                                            Airport Officer
                                          </span>
                                        </p>
                                        <p className="truncate text-sm text-gray-500">
                                          {officer.email}
                                        </p>
                                      </Link>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                    </div>
                    {airport &&
                    airport.hasOwnProperty("enabled") &&
                    airport.enabled ? (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Disable Airport
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Disable an airport so it cannot be used to create
                            future visitor passes
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => setOpenDisableAirportModal(true)}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-50"
                          >
                            <BanIcon className="h-4 w-4 mr-1" />
                            Disable Airport
                          </button>
                        </div>
                        <DeleteModal
                          description="Disabling an airport will prevent any future visitor passes from being created for the airport"
                          open={openDisableAirportModal}
                          setOpen={setOpenDisableAirportModal}
                          title={
                            "Are you sure you want to disable this airport?"
                          }
                          action={() => disableAirport()}
                          actionButtonText={"Disable"}
                        />
                      </div>
                    ) : (
                      <div className="pt-8">
                        <div>
                          <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Enable Airport
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Enable an airport so it can be used to create future
                            visitor passes
                          </p>
                        </div>
                        <div className="mt-6">
                          <button
                            type="button"
                            disabled={loading}
                            onClick={() => enableAirport()}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 disabled:opacity-50"
                          >
                            <CheckIcon className="h-4 w-4 mr-1" />
                            Enable Airport
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="pt-8">
                      <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                          Delete Airport
                        </h3>
                        {airportDeletable ? (
                          <p className="mt-1 text-sm text-gray-500">
                            Permanently delete an airport from the Visitor Pass
                            database
                          </p>
                        ) : (
                          <p className="mt-1 text-sm text-gray-300">
                            This airport cannot be deleted since there are
                            visitor passes that belong to it.
                          </p>
                        )}
                      </div>
                      <div className="mt-6">
                        <button
                          type="button"
                          disabled={loading || !airportDeletable}
                          onClick={() => setOpenDeleteAirportModal(true)}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800 disabled:opacity-50"
                        >
                          <TrashIcon className="h-4 w-4 mr-1" />
                          Delete Airport
                        </button>
                      </div>
                      <DeleteModal
                        description="Deleting an airport is permanent. No visitor passes will be able to be created for this airport and it will be erased from Visitor Pass' databases."
                        open={openDeleteAirportModal}
                        setOpen={setOpenDeleteAirportModal}
                        title={"Are you sure you want to delete this airport?"}
                        action={() => deleteAirport()}
                        actionButtonText={"Delete"}
                      />
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/airport-management")}
                        type="button"
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => updateAirport()}
                        disabled={loading || !name || !timezone}
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Save
                      </button>
                      {(!name || !timezone) && (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to update airport
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully updated airport
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={deleteSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully deleted airport
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDeleteSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={disableSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully disabled airport
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setDisableSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={enableSuccess}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully enabled airport
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setEnableSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
