import { Transition } from "@headlessui/react";
import {
  ArrowLeftIcon,
  CheckCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { UserAddIcon } from "@heroicons/react/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../components/Sidebar/Sidebar";
import api from "../../../utils/helpers/api";
import debounce from "lodash.debounce";
import React from "react";

export default function AddVisitor() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingSuggestedAddress, setLoadingSuggestedAddress] =
    useState<boolean>(false);
  const [invalidRegister, setInvalidRegister] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [dob, setDob] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [suggestedAddress, setSuggestedAddress] = useState<string>("");
  const [regErrorMessages, setRegErrorMessages] = useState<string[]>([]);

  const addVisitor = async () => {
    setLoading(true);
    try {
      await api.createVisitor({
        email,
        gender,
        company,
        dob,
        firstName,
        lastName,
        password,
        phone: mobileNumber,
      });
      setSuccess(true);
      setTimeout(() => navigate("/visitor-management"), 3000);
    } catch (error) {
      console.error("Error occurred while creating visitor - ", error);
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    const newSignInErrorMessages = regErrorMessages.filter(
      (m) => m !== "Both passwords must match"
    );

    setInvalidRegister(false);

    if (
      !firstName ||
      !lastName ||
      !email ||
      !mobileNumber ||
      !dob ||
      !address ||
      !password ||
      !confirmPassword
    ) {
      setInvalidRegister(true);
    }

    if (password && password !== confirmPassword) {
      setRegErrorMessages([
        ...newSignInErrorMessages,
        "Both passwords must match",
      ]);
      setInvalidRegister(true);
    } else {
      setRegErrorMessages(newSignInErrorMessages);
    }
  }, [
    email,
    password,
    mobileNumber,
    firstName,
    lastName,
    address,
    confirmPassword,
  ]);

  const changeAddress = async (newAddress: string) => {
    setAddress(newAddress);
    setLoadingSuggestedAddress(true);
    // Validate address
    if (newAddress && newAddress !== suggestedAddress) {
      debouncedValidateAddress(newAddress);
    } else {
      setSuggestedAddress("");
    }
  };

  const debouncedValidateAddress = React.useRef(
    debounce(async (addressToValidate: string) => {
      const addressRes = await api.validateVisitorAddress(addressToValidate);
      setSuggestedAddress(addressRes.data);
      setLoadingSuggestedAddress(false);
    }, 1000)
  ).current;

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UserAddIcon className="h-7 w-7 mr-1.5" />
                    Add Visitor
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    Create a new visitor account
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    onClick={() => navigate(-1)}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <ArrowLeftIcon className="h-5 w-5 mr-1.5" />
                    Back
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col max-w-screen-md">
                <form className="space-y-8 divide-y divide-gray-200">
                  <div className="space-y-8">
                    <div>
                      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <div className="mt-1">
                            <input
                              id="first-name"
                              name="first-name"
                              type="text"
                              autoComplete="new-first-name"
                              onChange={(e) => setFirstName(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name
                          </label>
                          <div className="mt-1">
                            <input
                              id="last-name"
                              name="last-name"
                              type="text"
                              autoComplete="new-last-name"
                              onChange={(e) => setLastName(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              autoComplete="new-email"
                              onChange={(e) => setEmail(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="dob"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date of Birth
                          </label>
                          <div className="mt-1">
                            <input
                              id="dob"
                              name="dob"
                              type="date"
                              autoComplete="new-dob"
                              onChange={(e) => setDob(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="mobile-number"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Mobile Number
                          </label>
                          <div className="mt-1">
                            <input
                              id="mobile-number"
                              name="mobile-number"
                              type="text"
                              autoComplete="new-mobile-number"
                              onChange={(e) => setMobileNumber(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Company
                          </label>
                          <div className="mt-1">
                            <input
                              id="company"
                              name="company"
                              type="text"
                              autoComplete="new-company"
                              onChange={(e) => setCompany(e.target.value)}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="company"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address
                          </label>
                          <div className="mt-1">
                            <input
                              id="address"
                              name="address"
                              type="text"
                              autoComplete="new-address"
                              value={address}
                              onChange={(e) => changeAddress(e.target.value)}
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                          {loadingSuggestedAddress ? (
                            <label
                              htmlFor="company"
                              className="mt-1.5 block text-xs font-medium text-gray-700"
                            >
                              Loading suggested address...
                            </label>
                          ) : (
                            suggestedAddress &&
                            suggestedAddress !== address && (
                              <>
                                <label
                                  htmlFor="company"
                                  className="mt-1.5 block text-xs font-medium text-gray-700"
                                >
                                  Suggested
                                </label>
                                <div
                                  onClick={() => {
                                    setAddress(suggestedAddress);
                                    setSuggestedAddress("");
                                  }}
                                  className="mt-1 px-3 py-2 bg-white border border-gray-300 text-sm rounded-md shadow-sm hover:bg-indigo-600 hover:text-white cursor-pointer"
                                >
                                  {suggestedAddress}
                                </div>
                              </>
                            )
                          )}
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="new-password"
                              onChange={(e) => setPassword(e.target.value)}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="col-span-6">
                          <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                          </label>
                          <div className="mt-1">
                            <input
                              id="confirm-password"
                              name="confirm-password"
                              type="password"
                              autoComplete="confirm-password"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-amber-500 focus:border-amber-500 sm:text-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pt-5">
                    <div className="flex justify-start items-center">
                      <button
                        onClick={() => navigate("/visitor-management")}
                        type="button"
                        disabled={loading}
                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        onClick={() => addVisitor()}
                        disabled={
                          loading ||
                          password !== confirmPassword ||
                          !firstName ||
                          !lastName ||
                          !email ||
                          !mobileNumber ||
                          !password ||
                          !confirmPassword
                        }
                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 disabled:opacity-60"
                      >
                        Create
                      </button>
                      {!firstName ||
                      !lastName ||
                      !email ||
                      !mobileNumber ||
                      !password ||
                      !confirmPassword ? (
                        <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                          Please enter all the required fields to create a
                          visitor
                        </div>
                      ) : (
                        password !== confirmPassword && (
                          <div className="ml-3 text-red-500 text-sm font-semibold border-none">
                            Passwords don't match
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={success}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      Successfully created visitor
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Returning to visitor management portal...
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setSuccess(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
          <Transition
            show={error}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <XIcon
                      className="h-6 w-6 text-red-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      An error occurred
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      Please try again or contact support
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      type="button"
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                      onClick={() => {
                        setError(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
