import {
  BanIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FlagIcon,
  PlusIcon,
  RefreshIcon,
  UserIcon,
} from "@heroicons/react/outline";
import { UsersIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchForm from "../../components/SearchForm/SearchForm";
import Sidebar from "../../components/Sidebar/Sidebar";
import SortButton from "../../components/SortButton/SortButton";
import api from "../../utils/helpers/api";
import { classNames } from "../../utils/helpers/misc";
import { useAuthToken } from "../../hooks/useAuthToken";
import { usePageInitialization } from "../../hooks/usePageInitialization";

export default function VisitorManagement() {
  const { isInitiated } = usePageInitialization();
  const navigate = useNavigate();
  const [visitors, setVisitors] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [status, setStatus] = useState<string>("");

  useAuthToken();

  useEffect(() => {
    if (!isInitiated) return;
    fetchVisitors();
  }, [
    pageNumber,
    searchQuery,
    searchField,
    sortOrder,
    sortField,
    status,
    isInitiated,
  ]);

  const fetchVisitors = async () => {
    setLoading(true);
    try {
      const visitors = await api.getVisitors(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        status
      );
      if (visitors.status === 200 && visitors.data.data) {
        setTotalCount(visitors.data.totalCount);
        setVisitors(visitors.data.data);
      }
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} visitors of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const createPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalCount / rowsPerPage); i++) {
      pageNumbers.push(
        <button
          onClick={() => setPageNumber(i)}
          aria-current="page"
          disabled={loading}
          className={
            pageNumber === i
              ? "z-10 bg-amber-50 border-amber-500 text-amber-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
              : "border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer"
          }>
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const tabs = [
    {
      name: "All",
      onClick: () => setStatus(""),
      icon: UserIcon,
      current: status === "",
    },
    {
      name: "Active",
      onClick: () => setStatus("1"),
      icon: CheckCircleIcon,
      current: status === "1",
    },
    {
      name: "Flagged",
      onClick: () => setStatus("2"),
      icon: FlagIcon,
      current: status === "2",
    },
    {
      name: "Blocked",
      onClick: () => setStatus("3"),
      icon: BanIcon,
      current: status === "3",
    },
    {
      name: "Duplicate",
      onClick: () => setStatus("4"),
      icon: UsersIcon,
      current: status === "4",
    },
  ];

  return (
    <>
      <Sidebar />
      <div className="md:pl-64 flex flex-col flex-1">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-2xl font-bold text-gray-900 flex flex-row items-center">
                    <UsersIcon className="h-6 w-6 mr-1.5" />
                    Visitor Management
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the visitors in Visitor Pass including their
                    details.
                  </p>
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none items-center flex flex-row">
                  <button
                    onClick={() => navigate("/visitor-management/add")}
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-amber-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-amber-500 focus:ring-offset-2 sm:w-auto">
                    <PlusIcon className="h-5 w-5 mr-1.5" />
                    Add visitor
                  </button>
                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchVisitors()}
                    className="ml-2 inline-flex items-center justify-center rounded-md border border-transparent bg-stone-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600">
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="mt-4 flex flex-col">
                <div className="mb-4">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full rounded-md border-gray-300 focus:border-amber-500 focus:ring-amber-500"
                      defaultValue={tabs.find((tab) => tab.current)!.name}>
                      {tabs.map((tab) => (
                        <option onClick={tab.onClick} key={tab.name}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            onClick={tab.onClick}
                            className={classNames(
                              tab.current
                                ? "border-amber-500 text-amber-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}>
                            <tab.icon
                              className={classNames(
                                tab.current
                                  ? "text-amber-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            <span>{tab.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <SearchForm
                  searchOptions={[
                    { name: "All", value: "all" },
                    { name: "Email", value: "email" },
                    { name: "First Name", value: "firstName" },
                    { name: "Last Name", value: "lastName" },
                    { name: "Phone Number", value: "phone" },
                  ]}
                  updateSearchQueryAndField={(
                    newSearchQuery,
                    newSearchField
                  ) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                />
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Created At
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                VP Number
                                <SortButton
                                  active={sortField === "vpNumber"}
                                  sortOrder={sortOrder}
                                  sortField={"vpNumber"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Email
                                <SortButton
                                  active={sortField === "email"}
                                  sortOrder={sortOrder}
                                  sortField={"email"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                First Name
                                <SortButton
                                  active={sortField === "firstName"}
                                  sortOrder={sortOrder}
                                  sortField={"firstName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Last Name
                                <SortButton
                                  active={sortField === "lastName"}
                                  sortOrder={sortOrder}
                                  sortField={"lastName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Phone Number
                                <SortButton
                                  active={sortField === "phone"}
                                  sortOrder={sortOrder}
                                  sortField={"phone"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              <div className="inline-flex items-center">
                                Status
                                <SortButton
                                  active={sortField === "status"}
                                  sortOrder={sortOrder}
                                  sortField={"status"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                              <span className="sr-only">View</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {visitors.map((visitor) => (
                            <tr
                              key={visitor.id}
                              className="hover:bg-slate-50 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  "/visitor-management/edit/" + visitor.id
                                )
                              }>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(visitor.createdAt).toDateString()}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.vpNumber ?? "N/A"}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.email}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.firstName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.lastName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.phone}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {visitor.status === 1 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    Active
                                  </div>
                                )}
                                {visitor.status === 2 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-800">
                                    Flagged
                                  </div>
                                )}
                                {visitor.status === 3 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                    Blocked
                                  </div>
                                )}
                                {visitor.status === 4 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                    Duplicate
                                  </div>
                                )}
                                {visitor.status === 0 && (
                                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-900">
                                    Unknown
                                  </div>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={"/visitor-management/edit/" + visitor.id}>
                                  <div className="text-amber-600 hover:text-amber-900">
                                    View
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="py-3 flex items-center justify-between border-t border-gray-200">
                      <div className="flex-1 flex justify-between sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Previous
                        </a>
                        <a
                          href="#"
                          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 hover:bg-gray-50">
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div>
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <p className="text-sm text-gray-700">
                              Showing{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber - rowsPerPage + 1}
                              </span>{" "}
                              to{" "}
                              <span className="font-medium">
                                {rowsPerPage * pageNumber > totalCount
                                  ? totalCount
                                  : rowsPerPage * pageNumber}
                              </span>{" "}
                              of{" "}
                              <span className="font-medium">{totalCount}</span>{" "}
                              results
                            </p>
                          ) : (
                            <p className="text-sm text-gray-700">
                              Showing 0 results
                            </p>
                          )}
                        </div>
                        <div>
                          <nav
                            className="bg-white relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination">
                            <button
                              onClick={() => setPageNumber(pageNumber - 1)}
                              disabled={pageNumber === 1 || loading}
                              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Previous</span>
                              <ChevronLeftIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                            {/* Current: "z-10 bg-amber-50 border-amber-500 text-amber-600", Default: "border-gray-300 text-gray-500 hover:bg-gray-50" */}
                            {createPageNumbers()}
                            <button
                              onClick={() => setPageNumber(pageNumber + 1)}
                              disabled={
                                rowsPerPage * pageNumber > totalCount || loading
                              }
                              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-60 disabled:hover:bg-white">
                              <span className="sr-only">Next</span>
                              <ChevronRightIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
