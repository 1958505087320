import {
  CameraIcon,
  DocumentTextIcon,
  RefreshIcon,
} from "@heroicons/react/outline";
import { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import {
  clearResults,
  connect,
  getImages,
  initRegulaReader,
} from "../../../utils/regula/api";
import { getLastResults } from "../../../utils/regula/other";
import {
  clearStoreResults,
  useRegulaDispatch,
  useRegulaSelector,
} from "../../../utils/regula/store";
import PlaceholderImage from "../../../assets/img/placeholder-image.jpeg";
interface IDPhotoProps {
  idPhotoFront: string;
  setIdPhotoFront: (newFirstName: string) => void;
  idDocumentNumber: string;
  setIdDocumentNumber: (newIdDocumentNumber: string) => void;
  existingVisitorFound: boolean;
  // idDocumentExpiry: string;
  // setIdDocumentExpiry: (newIdDocumentExpiry: string) => void;
  setInvalidForm: (newInvalidForm: boolean) => void;
}

export const IDPhoto = (props: IDPhotoProps) => {
  const [webcamMode, setWebcamMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [facingMode, setFacingMode] = useState<string>("environment");
  const webcamRef = useRef<any>();
  const [error, setError] = useState<string>("");
  const {
    images,
    data,
    loading: regulaLoading,
  } = useRegulaSelector((state) => state);
  const dispatch = useRegulaDispatch();

  useEffect(() => {
    dispatch(clearStoreResults());

    // Check if Regula scanner is available
    let url = process.env.REACT_APP_REGULA_SERVICE_URL;

    if (!url) {
      console.error("REACT_APP_REGULA_SERVICE_URL is not defined");
      setWebcamMode(true);
    } else {
      //Getting everything ready
      setWebcamMode(false);
      initRegulaReader(url, false);
      // getLastResults();
    }
  }, []);

  useEffect(() => {
    if (webcamMode) {
      dispatch(clearStoreResults());
    }
  }, [webcamMode]);

  useEffect(() => {
    if (images.length > 0) {
      props.setIdPhotoFront(images[0]);
    }

    props.setInvalidForm(!props.idPhotoFront);
  }, [images, props]);

  useEffect(() => {
    if (data.length > 0) {
      const documentNumberElement = data.filter(
        (e) => e.field === "strDocumentNumber"
      )[0];

      if (documentNumberElement) {
        props.setIdDocumentNumber(documentNumberElement.value);
      }

      // const documentExpiryElement = data.filter(
      //   (e) => e.field === "strDateofExpiry"
      // )[0];

      // if (documentExpiryElement) {
      //   props.setIdDocumentExpiry(documentExpiryElement.value);
      // }
    } else {
      props.setIdDocumentNumber("");
      // props.setIdDocumentExpiry("");
    }
  }, [data]);

  useEffect(() => {
    setLoading(regulaLoading);
  }, [regulaLoading]);

  const scanIdPhoto = () => {
    setLoading(true);
    try {
      dispatch(clearStoreResults());
      clearResults(connect(getImages(getLastResults())));
    } catch (error) {
      setError(
        "An unknown error occurred. Please try again or contact support."
      );
    }
    setLoading(false);
    setTimeout(() => setError(""), 2000);
  };

  const capturePhoto = () => {
    try {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          props.setIdPhotoFront(imageSrc);
        } else {
          setError("Loading webcam... please wait.");
        }
      } else {
        setError("Loading webcam... please wait.");
      }
    } catch (error) {
      setError(
        "An unknown error occurred. Please try again or contact support."
      );
    }
    setTimeout(() => setError(""), 2000);
  };

  const showAsteriskIfEmpty = (field: string) => {
    if (!field) {
      return <span className="text-red-600 font-bold ml-0.5">*</span>;
    }
  };

  return (
    <div className="max-w-screen-sm px-4 mt-4 sm:px-0">
      <div className="">
        <label className="inline-block mb-2 text-gray-500">
          Scan the front of the visitor's ID
          {showAsteriskIfEmpty(props.idPhotoFront)}
        </label>
        {data.length > 0 && (
          <div className="text-sm text-gray-700">
            <div>
              <span className="font-semibold">Document Number:</span>{" "}
              {props.idDocumentNumber}
              <span className="ml-1.5">
                {props.existingVisitorFound ? (
                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                    Existing Visitor Found
                  </div>
                ) : (
                  <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                    No Existing Visitor Found
                  </div>
                )}
              </span>
            </div>
          </div>
        )}
        <div className="flex w-full">
          <div className="relative py-2 overflow-hidden text-left transition-all transform sm:max-w-lg sm:w-full">
            <div className="sm:flex sm:items-start">
              <div className="mt-0 text-center sm:text-left">
                <div className="mt-2 rounded-md">
                  <div className="flex flex-col items-center justify-center">
                    {loading ? (
                      <span className="mb-2 text-gray-500">Loading...</span>
                    ) : props.idPhotoFront ? (
                      <img
                        alt="ID Front"
                        className="w-full h-full rounded-md"
                        src={props.idPhotoFront}
                      />
                    ) : webcamMode ? (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={1000}
                        height={1000}
                        videoConstraints={{ facingMode }}
                      />
                    ) : (
                      <img
                        alt="ID Front"
                        className="w-full h-full rounded-md"
                        src={PlaceholderImage}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 sm:flex">
              {webcamMode ? (
                props.idPhotoFront ? (
                  <>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                      onClick={() => props.setIdPhotoFront("")}
                    >
                      <CameraIcon className="w-5 h-5" />
                      <span className="ml-1.5">Retake</span>
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                      onClick={() => {
                        props.setIdPhotoFront("");
                        setWebcamMode(false);
                      }}
                    >
                      <DocumentTextIcon className="w-5 h-5" />
                      <span className="ml-1.5">Switch to Scanner</span>
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                      onClick={() => capturePhoto()}
                    >
                      <CameraIcon className="w-5 h-5" />
                      <span className="ml-1.5">Capture</span>
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-stone-500 hover:bg-stone-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stone-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                      onClick={() =>
                        facingMode === "user"
                          ? setFacingMode("environment")
                          : setFacingMode("user")
                      }
                    >
                      <RefreshIcon className="w-5 h-5" />
                      <span className="ml-1.5">Flip</span>
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 mt-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                      onClick={() => {
                        props.setIdPhotoFront("");
                        setWebcamMode(false);
                      }}
                    >
                      <DocumentTextIcon className="w-5 h-5" />
                      <span className="ml-1.5">Switch to Scanner</span>
                    </button>
                  </>
                )
              ) : props.idPhotoFront ? (
                <>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => scanIdPhoto()}
                  >
                    <CameraIcon className="w-5 h-5" />
                    <span className="ml-1.5">Rescan</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 mt-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => {
                      dispatch(clearStoreResults());
                      props.setIdPhotoFront("");
                      setWebcamMode(true);
                    }}
                  >
                    <CameraIcon className="w-5 h-5" />
                    <span className="ml-1.5">Switch to Camera</span>
                  </button>
                </>
              ) : (
                <>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => scanIdPhoto()}
                  >
                    <DocumentTextIcon className="w-5 h-5" />
                    <span className="ml-1.5">Scan</span>
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center w-full px-4 py-2 mt-2 text-base font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:mr-3 sm:w-auto sm:text-sm disabled:opacity-80"
                    onClick={() => {
                      props.setIdPhotoFront("");
                      setWebcamMode(true);
                    }}
                  >
                    <CameraIcon className="w-5 h-5" />
                    <span className="ml-1.5">Switch to Camera</span>
                  </button>
                </>
              )}
            </div>
            {error && <div className="mt-4 text-sm text-red-600">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
