/* eslint-disable @typescript-eslint/no-explicit-any */
import { Strings } from "./strings";

/*=====Utilities=====*/
export function getFromEnum(
  set: { [x: string]: unknown; hasOwnProperty: (arg0: string) => unknown },
  value: unknown
) {
  for (const k in set) {
    // eslint-disable-next-line no-prototype-builtins
    if (set.hasOwnProperty(k)) {
      if (set[k] == value) {
        return k;
      }
    }
  }
  return undefined;
}

export function getTranslation(value: any) {
  const cookieLang = getCookie("language");

  const entry = Strings[value as string];
  if (entry) {
    const result = entry[cookieLang as string];
    if (result != null) return result;
  }

  return value;
}

function getCookie(name: string) {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + "=")) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

export function trimChar(string: string, charToRemove: string) {
  while (string.charAt(0) == charToRemove) {
    string = string.substring(1);
  }

  while (string.charAt(string.length - 1) == charToRemove) {
    string = string.substring(0, string.length - 1);
  }

  return string;
}
