import { useEffect } from "react";
import { useAuth } from "../utils/auth/auth";
import api from "../utils/helpers/api";

export const useAuthToken = () => {
  const auth = useAuth();
  useEffect(() => {
    if (!auth.loading && auth.user) {
      api.updateClient(auth.token);
    }
  }, [auth.user]);
};
